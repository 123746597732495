import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import noop from 'lodash/noop'
import sortBy from 'lodash/sortBy'

import { parseDateToLocationString } from 'utils/utils'
import { SORTING_RESULT_ITEMS } from 'utils/constants'

import Sorting from 'components/Sorting'
import Loader from 'components/Loader'
import image from 'images/empty.png'
import IconTrash from 'icons/trash'
import IconCalendar from 'icons/calendar'
import IconDone from 'icons/done'

import { selectResultsEntities, selectIsLoading } from 'store/results/selectors'
import { actionDeleteResulsRequest } from 'store/results/actions'

import styles from './styles.module.scss'

const mapStateToProps = (state) => ({
  results: selectResultsEntities(state),
  isLoading: selectIsLoading(state),
})

const mapDispatchToProps = {
  removeResult: actionDeleteResulsRequest,
}

const Complited = ({ results, isLoading, removeResult, history }) => {
  const [sortedResult, setSortedResult] = useState(results)
  const [selectedSorting, setSelectedSorting] = useState('create_at')

  const getSelectedItem = useCallback(() => {
    return SORTING_RESULT_ITEMS.find((i) => i.value === selectedSorting) || {}
  }, [selectedSorting])

  const handleDelete = useCallback(
    (id) => (e) => {
      e.stopPropagation()
      removeResult({ id })
    },
    [removeResult]
  )

  const selectOil = useCallback(
    (id) => () => {
      history.push(`result/${id}`)
    },
    [history]
  )

  const handleSorting = useCallback(
    (type) => {
      setSortedResult(sortBy(sortedResult, type))
      setSelectedSorting(type)
    },
    [sortedResult]
  )

  useEffect(() => {
    setSortedResult(results)
  }, [results])

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      <div className={styles.title}>
        <IconDone size={30} />
        Пройденные тесты
      </div>
      {sortedResult.length === 0 && (
        <div className={styles.empty}>
          <img src={image} alt="empty" />
          Список тестов пуст
        </div>
      )}
      {sortedResult.length > 0 && (
        <>
          <Sorting
            onSelect={handleSorting}
            items={SORTING_RESULT_ITEMS}
            selectedItem={getSelectedItem()}
          />
          <div className={styles.list}>
            {sortedResult.map((result) => (
              <div key={result._id} className={styles.result} onClick={selectOil(result._id)}>
                <span>
                  {result.first_name[0]}
                  {result.last_name[0]}
                </span>
                <div className={styles.body}>
                  <span title={result.title}>{`${result.first_name} ${result.last_name}`}</span>
                  <span className={styles.email}>{result.email}</span>
                  <div className={styles.date}>
                    <IconCalendar size={13} />
                    <span>{parseDateToLocationString(result.create_at)}</span>
                  </div>
                </div>
                <div className={styles.delete} onClick={handleDelete(result._id)}>
                  <IconTrash size={20} />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

Complited.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})),
  removeResult: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  isLoading: PropTypes.bool,
}

Complited.defaultProps = {
  results: [],
  removeResult: noop,
  history: {},
  isLoading: false,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Complited)
