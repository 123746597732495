import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import { getDefaultLoginFields } from 'utils/fields'

import Copyright from 'icons/copyright'
import IconLogo from 'icons/logo'
import Input from 'components/Input'
import RectangleButton from 'components/RectangleButton'
import { actionAuthRequest } from 'store/auth/actions'

import styles from './styles.module.scss'

const mapDispatchToState = {
  login: actionAuthRequest,
}

const Login = ({ login }) => {
  const [fields, setFields] = useState(getDefaultLoginFields())

  const handleChangeInput = useCallback(
    (e) => {
      const { name, value } = e.target
      setFields({ ...fields, [name]: value })
    },
    [fields]
  )

  const loginHandler = useCallback(() => {
    login(fields)
  }, [fields, login])

  const keyPressHandler = useCallback(
    (e) => {
      if (e.code === 'Enter') {
        login(fields)
      }
    },
    [fields, login]
  )

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <div className={styles.logo}>
          <IconLogo size={180} />
        </div>
        <Input
          name="email"
          label="Email"
          placeholder="Введите Email..."
          handleChange={handleChangeInput}
          value={fields.email}
          handleKeyPress={keyPressHandler}
        />
        <Input
          name="password"
          label="Пароль"
          type="password"
          placeholder="Введите пароль..."
          handleChange={handleChangeInput}
          value={fields.password}
          handleKeyPress={keyPressHandler}
        />
        <RectangleButton title="Войти" theme="blue" onClick={loginHandler} />
        <p className={styles.copyright}>
          <Copyright size={16} />
          by Lermonka
        </p>
      </div>
    </div>
  )
}

Login.propTypes = {
  login: PropTypes.func,
}

Login.defaultProps = {
  login: noop,
}

export default connect(null, mapDispatchToState)(Login)
