import { handleActions } from 'redux-actions'

import {
  actionLoadResultsRequest,
  actionLoadResultsSuccess,
  actionCreateResulsSuccess,
  actionDeleteResulsSuccess,
} from './actions'

const initialState = {
  entities: [],
  isLoading: false,
  isCreating: false,
}

export default handleActions(
  {
    [actionLoadResultsRequest]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [actionLoadResultsSuccess]: (state, { payload }) => ({
      ...state,
      entities: payload,
      isLoading: false,
    }),

    [actionCreateResulsSuccess]: (state, { payload }) => ({
      ...state,
      entities: [...state.entities, payload],
    }),

    [actionDeleteResulsSuccess]: (state, { payload }) => ({
      ...state,
      entities: state.entities.filter((r) => r._id !== payload),
    }),
  },
  initialState
)
