import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import noop from 'lodash/noop'

import styles from './styles.module.scss'

const DragAndDropArea = ({ children, onDrop, onSelect, multiSelect }) => {
  const [dropDepth, setDropDepth] = useState(0)

  const handleDragOver = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
  })

  const handleDragEnter = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      setDropDepth(dropDepth + 1)
    },
    [dropDepth, setDropDepth]
  )

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      setDropDepth(dropDepth - 1)
    },
    [dropDepth]
  )

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault()
      e.stopPropagation()

      const { dataTransfer } = e

      setDropDepth(0)

      if (dataTransfer.files.length > 1 && !multiSelect) {
        toastr.warning('Можно перенести только одни файл')
      } else {
        const filtredFiles = Object.values(dataTransfer.files).filter((file) => !!file.type)
        onDrop(filtredFiles)
        onSelect()
      }
    },
    [setDropDepth, onDrop, onSelect, multiSelect]
  )

  return (
    <div
      className={styles.container}
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
    >
      {children}
    </div>
  )
}

DragAndDropArea.propTypes = {
  children: PropTypes.node.isRequired,
  onDrop: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  multiSelect: PropTypes.bool,
  renderContent: PropTypes.func,
  showOverlay: PropTypes.bool,
}

DragAndDropArea.defaultProps = {
  onSelect: noop,
  multiSelect: false,
  renderContent: noop,
  showOverlay: true,
}

export default DragAndDropArea
