import React, { useCallback, useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import sortBy from 'lodash/sortBy'

import { parseDateToLocationString } from 'utils/utils'
import { SORTING_OIL_ITEMS } from 'utils/constants'
import { sortingByGroup } from 'utils/sorting'

import image from 'images/empty.png'
import IconTrash from 'icons/trash'
import IconListOils from 'icons/listOils'
import IconCalendar from 'icons/calendar'
import Sorting from 'components/Sorting'

import { selectOilsEntities } from 'store/oils/selectors'
import { actionDeleteOilRequest } from 'store/oils/actions'

import styles from './styles.module.scss'

const mapStateToProps = (state) => ({
  oils: selectOilsEntities(state),
})

const mapDispatchToProps = {
  removeOil: actionDeleteOilRequest,
}

const OilsList = ({ oils, removeOil, history }) => {
  const [sortedOils, setSortedOils] = useState(oils)
  const [selectedSorting, setSelectedSorting] = useState('create_at')

  const getSelectedItem = useCallback(() => {
    return SORTING_OIL_ITEMS.find((i) => i.value === selectedSorting) || {}
  }, [selectedSorting])

  const handleDelete = useCallback(
    (id) => (e) => {
      e.stopPropagation()
      removeOil({ id })
    },
    [removeOil]
  )

  const handleSorting = useCallback(
    (type) => {
      const sortedItems = type === 'group' ? sortingByGroup(sortedOils) : sortBy(sortedOils, type)
      setSortedOils(sortedItems)
      setSelectedSorting(type)
    },
    [sortedOils]
  )

  const selectOil = useCallback(
    (id) => () => {
      history.push(`oil/${id}`)
    },
    [history]
  )

  useEffect(() => {
    setSortedOils([...oils])
  }, [oils])

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <IconListOils size={30} />
        Список масел
      </div>
      <Sorting
        onSelect={handleSorting}
        items={SORTING_OIL_ITEMS}
        selectedItem={getSelectedItem()}
      />
      {sortedOils.length === 0 && (
        <div className={styles.empty}>
          <img src={image} alt="empty" />
          Список масел пуст
        </div>
      )}
      {sortedOils.length > 0 && (
        <div className={styles.list}>
          {sortedOils.map((oil, index) => {
            return (
              <div key={oil._id} className={styles.oil} onClick={selectOil(oil._id)}>
                <span>{index + 1}</span>
                <img src={oil.cover.preview_path || oil.cover.path} alt={oil.en_title} />
                <div className={styles.body}>
                  <span title={oil.title}>{oil.title}</span>
                  <div className={styles.date}>
                    <IconCalendar size={13} />
                    <span>{parseDateToLocationString(oil.create_at)}</span>
                  </div>
                </div>
                <div className={styles.delete} onClick={handleDelete(oil._id)}>
                  <IconTrash size={20} />
                </div>
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

OilsList.propTypes = {
  oils: PropTypes.arrayOf(PropTypes.shape({})),
  removeOil: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
}

OilsList.defaultProps = {
  oils: [],
  removeOil: noop,
  history: {},
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(OilsList)
