import React from 'react'
import PropTypes from 'prop-types'

const Logout = ({ size, color }) => (
  <svg
    width={size}
    height={(22 * size) / 24}
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 4.8L16.308 6.492L19.404 9.6H7.2V12H19.404L16.308 15.096L18 16.8L24 10.8L18 4.8ZM2.4 2.4H12V0H2.4C1.08 0 0 1.08 0 2.4V19.2C0 20.52 1.08 21.6 2.4 21.6H12V19.2H2.4V2.4Z"
      fill={color}
    />
  </svg>
)

Logout.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Logout.defaultProps = {
  size: 24,
  color: 'currentColor',
}

export default Logout
