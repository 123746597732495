import React from 'react'
import PropTypes from 'prop-types'

const Calendar = ({ size, color }) => (
  <svg
    width={size}
    height={(15 * size) / 16}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1251 2H13.5001V3H15.0001V14H1.00013V3H2.50013V2H0.875129C0.758247 2.00195 0.642895 2.02691 0.535662 2.07345C0.428428 2.11999 0.331414 2.1872 0.250159 2.27125C0.168905 2.35529 0.105002 2.45451 0.0621014 2.56325C0.0192006 2.67199 -0.00185798 2.78812 0.000128526 2.905V14.095C-0.00185798 14.2119 0.0192006 14.328 0.0621014 14.4367C0.105002 14.5455 0.168905 14.6447 0.250159 14.7288C0.331414 14.8128 0.428428 14.88 0.535662 14.9265C0.642895 14.9731 0.758247 14.998 0.875129 15H15.1251C15.242 14.998 15.3574 14.9731 15.4646 14.9265C15.5718 14.88 15.6688 14.8128 15.7501 14.7288C15.8314 14.6447 15.8953 14.5455 15.9382 14.4367C15.9811 14.328 16.0021 14.2119 16.0001 14.095V2.905C16.0021 2.78812 15.9811 2.67199 15.9382 2.56325C15.8953 2.45451 15.8314 2.35529 15.7501 2.27125C15.6688 2.1872 15.5718 2.11999 15.4646 2.07345C15.3574 2.02691 15.242 2.00195 15.1251 2V2Z"
      fill={color}
    />
    <path d="M3.00012 6H4.00012V7H3.00012V6Z" fill={color} />
    <path d="M6.00012 6H7.00012V7H6.00012V6Z" fill={color} />
    <path d="M9.00012 6H10.0001V7H9.00012V6Z" fill={color} />
    <path d="M12.0001 6H13.0001V7H12.0001V6Z" fill={color} />
    <path d="M3.00012 8.5H4.00012V9.5H3.00012V8.5Z" fill={color} />
    <path d="M6.00012 8.5H7.00012V9.5H6.00012V8.5Z" fill={color} />
    <path d="M9.00012 8.5H10.0001V9.5H9.00012V8.5Z" fill={color} />
    <path d="M12.0001 8.5H13.0001V9.5H12.0001V8.5Z" fill={color} />
    <path d="M3.00012 11H4.00012V12H3.00012V11Z" fill={color} />
    <path d="M6.00012 11H7.00012V12H6.00012V11Z" fill={color} />
    <path d="M9.00012 11H10.0001V12H9.00012V11Z" fill={color} />
    <path d="M12.0001 11H13.0001V12H12.0001V11Z" fill={color} />
    <path
      d="M4.00012 4C4.13273 4 4.25991 3.94732 4.35368 3.85355C4.44744 3.75979 4.50012 3.63261 4.50012 3.5V0.5C4.50012 0.367392 4.44744 0.240215 4.35368 0.146447C4.25991 0.0526784 4.13273 0 4.00012 0C3.86751 0 3.74034 0.0526784 3.64657 0.146447C3.5528 0.240215 3.50012 0.367392 3.50012 0.5V3.5C3.50012 3.63261 3.5528 3.75979 3.64657 3.85355C3.74034 3.94732 3.86751 4 4.00012 4Z"
      fill={color}
    />
    <path
      d="M12.0001 4C12.1327 4 12.2599 3.94732 12.3537 3.85355C12.4474 3.75979 12.5001 3.63261 12.5001 3.5V0.5C12.5001 0.367392 12.4474 0.240215 12.3537 0.146447C12.2599 0.0526784 12.1327 0 12.0001 0C11.8675 0 11.7403 0.0526784 11.6466 0.146447C11.5528 0.240215 11.5001 0.367392 11.5001 0.5V3.5C11.5001 3.63261 11.5528 3.75979 11.6466 3.85355C11.7403 3.94732 11.8675 4 12.0001 4Z"
      fill={color}
    />
    <path d="M5.50012 2H10.5001V3H5.50012V2Z" fill={color} />
  </svg>
)

Calendar.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Calendar.defaultProps = {
  size: 16,
  color: 'currentColor',
}

export default Calendar
