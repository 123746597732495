import React from 'react'
import PropTypes from 'prop-types'

const UserData = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M28 8H30V16H28V8Z" fill={color} />
    <path d="M23 5H25V16H23V5Z" fill={color} />
    <path d="M18 10H20V16H18V10Z" fill={color} />
    <path
      d="M16 30H14V24C13.9992 23.2046 13.6829 22.442 13.1204 21.8796C12.558 21.3171 11.7954 21.0008 11 21H7C6.20459 21.0008 5.44199 21.3171 4.87956 21.8796C4.31712 22.442 4.00079 23.2046 4 24V30H2V24C2.00159 22.6744 2.52888 21.4036 3.46622 20.4662C4.40356 19.5289 5.6744 19.0016 7 19H11C12.3256 19.0016 13.5964 19.5289 14.5338 20.4662C15.4711 21.4036 15.9984 22.6744 16 24V30Z"
      fill={color}
    />
    <path
      d="M9 9C9.59335 9 10.1734 9.17595 10.6667 9.50559C11.1601 9.83524 11.5446 10.3038 11.7716 10.8519C11.9987 11.4001 12.0581 12.0033 11.9424 12.5853C11.8266 13.1672 11.5409 13.7018 11.1213 14.1213C10.7018 14.5409 10.1672 14.8266 9.58527 14.9424C9.00333 15.0581 8.40013 14.9987 7.85195 14.7716C7.30377 14.5446 6.83524 14.1601 6.50559 13.6667C6.17595 13.1734 6 12.5933 6 12C6 11.2044 6.31607 10.4413 6.87868 9.87868C7.44129 9.31607 8.20435 9 9 9V9ZM9 7C8.0111 7 7.0444 7.29324 6.22215 7.84265C5.39991 8.39206 4.75904 9.17295 4.3806 10.0866C4.00217 11.0002 3.90315 12.0055 4.09608 12.9755C4.289 13.9454 4.76521 14.8363 5.46447 15.5355C6.16373 16.2348 7.05465 16.711 8.02455 16.9039C8.99446 17.0969 9.99979 16.9978 10.9134 16.6194C11.827 16.241 12.6079 15.6001 13.1573 14.7779C13.7068 13.9556 14 12.9889 14 12C14 11.3434 13.8707 10.6932 13.6194 10.0866C13.3681 9.47995 12.9998 8.92876 12.5355 8.46447C12.0712 8.00017 11.52 7.63188 10.9134 7.3806C10.3068 7.12933 9.65661 7 9 7V7Z"
      fill={color}
    />
  </svg>
)

UserData.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

UserData.defaultProps = {
  size: 32,
  color: 'currentColor',
}

export default UserData
