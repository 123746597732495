import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { MENU } from 'routes'
import IconHome from 'icons/home'
import IconTesting from 'icons/testing'
import IconDone from 'icons/done'
import IconAddingOils from 'icons/addingOils'
import IconAddingresults from 'icons/addingResults'
import IconListOils from 'icons/listOils'
import IconMixes from 'icons/mixes'

import { selectIsSidebarOpen } from 'store/ui/selectors'

import styles from './styles.module.scss'

const ICONS_MAP = {
  HOME: <IconHome size={20} />,
  TESTING: <IconTesting size={20} />,
  COMPLITED: <IconDone size={20} />,
  ADDING_OILS: <IconAddingOils size={20} />,
  ADDING_RESULTS: <IconAddingresults size={20} />,
  LIST_OILS: <IconListOils size={20} />,
  MIXES: <IconMixes size={20} />,
}

const mapStateToProps = (state) => ({
  isSidebarOpen: selectIsSidebarOpen(state),
})

const Sidebar = ({ isSidebarOpen }) => {
  return (
    <div className={classNames(styles.sidebar, { [styles.close]: !isSidebarOpen })}>
      <nav>
        <ul className={styles.sidebarMenu}>
          {MENU.map((item) => (
            <li key={item.title}>
              <NavLink activeClassName={styles.active} to={item.to} exact>
                {ICONS_MAP[item.iconName]}
                {isSidebarOpen && <span>{item.title}</span>}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  )
}

Sidebar.propTypes = {
  isSidebarOpen: PropTypes.bool.isRequired,
}

export default connect(mapStateToProps, null)(Sidebar)
