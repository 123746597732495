import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { GROUP_OILS_ITEMS_MAP, VOLATILITY_OILS_ITEMS_MAP } from 'utils/constants'

import styles from './styles.module.scss'

const Viewing = ({ oil }) => {
  return (
    <>
      <div className={styles.preview}>
        <img src={`/${get(oil, 'cover.path')}`} alt={get(oil, 'cover.name')} />
      </div>
      <div className={styles.fields}>
        <div className={styles.field}>
          <span>Название</span>
          <span dangerouslySetInnerHTML={{ __html: oil.title }} />
        </div>
        <div className={styles.groupFieldsContainer}>
          <div className={styles.field}>
            <span>Группа</span>
            <span
              dangerouslySetInnerHTML={{
                __html: get(GROUP_OILS_ITEMS_MAP, `[${oil.group}].title`, ''),
              }}
            />
          </div>
          <div className={styles.field}>
            <span>Летучесть</span>
            <span
              dangerouslySetInnerHTML={{
                __html: get(VOLATILITY_OILS_ITEMS_MAP, `[${oil.volatility}].title`, ''),
              }}
            />
          </div>
        </div>
        <div className={styles.field}>
          <span>Плюс</span>
          <span dangerouslySetInnerHTML={{ __html: oil.plus }} />
        </div>
        <div className={styles.field}>
          <span>Минус</span>
          <span dangerouslySetInnerHTML={{ __html: oil.minus }} />
        </div>
        <div className={styles.field}>
          <span>Общее</span>
          <span dangerouslySetInnerHTML={{ __html: oil.general }} />
        </div>
        <div className={styles.field}>
          <span>Вопрос</span>
          <span dangerouslySetInnerHTML={{ __html: oil.question }} />
        </div>
      </div>
    </>
  )
}

Viewing.propTypes = {
  oil: PropTypes.shape({
    cover: PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    plus: PropTypes.string,
    minus: PropTypes.string,
    general: PropTypes.string,
    question: PropTypes.string,
    group: PropTypes.string,
    volatility: PropTypes.string,
  }),
}

Viewing.defaultProps = {
  oil: {
    cover: {
      path: '',
      name: '',
    },
    title: '',
    plus: '',
    minus: '',
    general: '',
    question: '',
    group: '',
    volatility: '',
  },
}

export default Viewing
