import React from 'react'
import PropTypes from 'prop-types'

const ListOils = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="13" height="13" rx="1.5" stroke={color} />
    <path
      d="M2.15 3.59394C2.15 3.50011 2.19608 3.33069 2.28148 3.10973C2.36417 2.89581 2.47528 2.65413 2.58765 2.42547C2.6998 2.19726 2.8121 1.98423 2.89645 1.82811C2.89969 1.82211 2.90289 1.81619 2.90605 1.81036C2.90921 1.81619 2.91241 1.82211 2.91565 1.82811C3 1.98423 3.11231 2.19726 3.22445 2.42547C3.33682 2.65413 3.44794 2.89581 3.53062 3.10973C3.61603 3.33069 3.6621 3.50011 3.6621 3.59394C3.6621 4.0115 3.32362 4.35 2.90605 4.35C2.48849 4.35 2.15 4.01149 2.15 3.59394Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
      strokeLinejoin="round"
    />
    <path
      d="M2.15 7.59394C2.15 7.50011 2.19608 7.33069 2.28148 7.10973C2.36417 6.89581 2.47528 6.65413 2.58765 6.42547C2.6998 6.19726 2.8121 5.98423 2.89645 5.82811C2.89969 5.82211 2.90289 5.81619 2.90605 5.81036C2.90921 5.81619 2.91241 5.82211 2.91565 5.82811C3 5.98423 3.11231 6.19726 3.22445 6.42547C3.33682 6.65413 3.44794 6.89581 3.53062 7.10973C3.61603 7.33069 3.6621 7.50011 3.6621 7.59394C3.6621 8.0115 3.32362 8.35 2.90605 8.35C2.48849 8.35 2.15 8.01149 2.15 7.59394Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
      strokeLinejoin="round"
    />
    <path
      d="M2.15 11.4939C2.15 11.4001 2.19608 11.2307 2.28148 11.0097C2.36417 10.7958 2.47528 10.5541 2.58765 10.3255C2.6998 10.0973 2.8121 9.88423 2.89645 9.72811C2.89969 9.72211 2.90289 9.71619 2.90605 9.71037C2.90921 9.71619 2.91241 9.72211 2.91565 9.72811C3 9.88423 3.11231 10.0973 3.22445 10.3255C3.33682 10.5541 3.44794 10.7958 3.53062 11.0097C3.61603 11.2307 3.6621 11.4001 3.6621 11.4939C3.6621 11.9115 3.32362 12.25 2.90605 12.25C2.48849 12.25 2.15 11.9115 2.15 11.4939Z"
      fill={color}
      stroke={color}
      strokeWidth="0.3"
      strokeLinejoin="round"
    />
    <path
      d="M7 3C7 2.86739 7.05268 2.74021 7.14645 2.64645C7.24021 2.55268 7.36739 2.5 7.5 2.5H10.5C10.6326 2.5 10.7598 2.55268 10.8536 2.64645C10.9473 2.74021 11 2.86739 11 3C11 3.13261 10.9473 3.25979 10.8536 3.35355C10.7598 3.44732 10.6326 3.5 10.5 3.5H7.5C7.36739 3.5 7.24021 3.44732 7.14645 3.35355C7.05268 3.25979 7 3.13261 7 3Z"
      fill={color}
    />
    <path
      d="M5 3C5 2.86739 5.05268 2.74021 5.14645 2.64645C5.24021 2.55268 5.36739 2.5 5.5 2.5H8.5C8.63261 2.5 8.75979 2.55268 8.85355 2.64645C8.94732 2.74021 9 2.86739 9 3C9 3.13261 8.94732 3.25979 8.85355 3.35355C8.75979 3.44732 8.63261 3.5 8.5 3.5H5.5C5.36739 3.5 5.24021 3.44732 5.14645 3.35355C5.05268 3.25979 5 3.13261 5 3Z"
      fill={color}
    />
    <path
      d="M7 11C7 10.8674 7.05268 10.7402 7.14645 10.6464C7.24021 10.5527 7.36739 10.5 7.5 10.5H10.5C10.6326 10.5 10.7598 10.5527 10.8536 10.6464C10.9473 10.7402 11 10.8674 11 11C11 11.1326 10.9473 11.2598 10.8536 11.3536C10.7598 11.4473 10.6326 11.5 10.5 11.5H7.5C7.36739 11.5 7.24021 11.4473 7.14645 11.3536C7.05268 11.2598 7 11.1326 7 11Z"
      fill={color}
    />
    <path
      d="M5 11C5 10.8674 5.05268 10.7402 5.14645 10.6464C5.24021 10.5527 5.36739 10.5 5.5 10.5H8.5C8.63261 10.5 8.75979 10.5527 8.85355 10.6464C8.94732 10.7402 9 10.8674 9 11C9 11.1326 8.94732 11.2598 8.85355 11.3536C8.75979 11.4473 8.63261 11.5 8.5 11.5H5.5C5.36739 11.5 5.24021 11.4473 5.14645 11.3536C5.05268 11.2598 5 11.1326 5 11Z"
      fill={color}
    />
    <path
      d="M5 7C5 6.86739 5.05268 6.74021 5.14645 6.64645C5.24021 6.55268 5.36739 6.5 5.5 6.5H8.5C8.63261 6.5 8.75979 6.55268 8.85355 6.64645C8.94732 6.74021 9 6.86739 9 7C9 7.13261 8.94732 7.25979 8.85355 7.35355C8.75979 7.44732 8.63261 7.5 8.5 7.5H5.5C5.36739 7.5 5.24021 7.44732 5.14645 7.35355C5.05268 7.25979 5 7.13261 5 7Z"
      fill={color}
    />
    <path
      d="M7 7C7 6.86739 7.05268 6.74021 7.14645 6.64645C7.24021 6.55268 7.36739 6.5 7.5 6.5H10.5C10.6326 6.5 10.7598 6.55268 10.8536 6.64645C10.9473 6.74021 11 6.86739 11 7C11 7.13261 10.9473 7.25979 10.8536 7.35355C10.7598 7.44732 10.6326 7.5 10.5 7.5H7.5C7.36739 7.5 7.24021 7.44732 7.14645 7.35355C7.05268 7.25979 7 7.13261 7 7Z"
      fill={color}
    />
  </svg>
)

ListOils.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

ListOils.defaultProps = {
  size: 14,
  color: 'currentColor',
}

export default ListOils
