import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location'
import Scrollbars from 'react-custom-scrollbars'
import { connect } from 'react-redux'
import noop from 'lodash/noop'

import { useRoutes } from 'routes'
import Header from 'containers/Header'
import Loader from 'components/Loader'
import Sidebar from 'containers/Sidebar'

import { selectIsAuthorized } from 'store/auth/selectors'
import { selectIsLoading } from 'store/ui/selectors'
import { actionLoadOilsRequest } from 'store/oils/actions'
import { actionLoadResultsRequest } from 'store/results/actions'

import styles from './styles.module.scss'

const mapStateToProps = (state) => ({
  isAuthorized: selectIsAuthorized(state),
  isLoading: selectIsLoading(state),
})

const mapDispatchToProps = {
  loadOils: actionLoadOilsRequest,
  loadResults: actionLoadResultsRequest,
}

const App = ({ isAuthorized, isLoading, loadOils, loadResults }) => {
  const routes = useRoutes(isAuthorized)

  useEffect(() => {
    if (isAuthorized) {
      loadOils()
      loadResults()
    }
  }, [isAuthorized, loadOils, loadResults])

  return (
    <BrowserRouter>
      <LastLocationProvider>
        {isAuthorized && (
          <Scrollbars autoHide>
            <div className={styles.layout}>
              <Header />
              <div className={styles.centerContainer}>
                <Sidebar />
                {routes}
              </div>
              {isLoading && <Loader />}
            </div>
          </Scrollbars>
        )}
        {!isAuthorized && <div className={styles.emptyLayout}>{routes}</div>}
      </LastLocationProvider>
    </BrowserRouter>
  )
}

App.propTypes = {
  isAuthorized: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadOils: PropTypes.func,
  loadResults: PropTypes.func,
}

App.defaultProps = {
  isAuthorized: false,
  isLoading: false,
  loadOils: noop,
  loadResults: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
