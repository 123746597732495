import React, { useState, useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import noop from 'lodash/noop'
import omit from 'lodash/omit'

import { getIdFormPathName } from 'utils/utils'
import { validateOilFields } from 'utils/validation'

import Loader from 'components/Loader'
import RectangleButton from 'components/RectangleButton'
import Editing from 'containers/Oil/Editing'
import Viewing from 'containers/Oil/Viewing'
import { selectOilById } from 'store/oils/selectors'
import { actionUpdateOilRequest } from 'store/oils/actions'

import styles from './styles.module.scss'

const mapStateToProps = (state) => ({
  oil: selectOilById(state, { id: getIdFormPathName() }),
})

const mapDispatchToProps = {
  updateOil: actionUpdateOilRequest,
}

const Oil = ({ oil, history, updateOil }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [fields, setFields] = useState({})
  const [file, setFile] = useState(null)

  const initFields = () => {
    setFields({ ...omit(oil, ['_id', 'user_id']) })
  }

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleEdit = useCallback(() => {
    setIsEdit(true)
  })

  const handleSave = useCallback(() => {
    if (validateOilFields(fields)) {
      setIsEdit(false)
      updateOil({ fields, id: oil._id, file: file ? file.file : null })
    }
  }, [fields, file, updateOil])

  const handleCancel = useCallback(() => {
    setIsEdit(false)
  })

  const handleChangeInput = useCallback(
    (name, value) => {
      setFields({ ...fields, [name]: value })
    },
    [fields]
  )

  const handleUpload = useCallback((event) => {
    const file = event.target.files[0]

    if (file) {
      const fileURL = URL.createObjectURL(file)
      setFile({ name: file.name, fileURL, file })
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(oil) && isLoading) {
      setIsLoading(false)
    }

    if (!isEmpty(oil) && isEmpty(fields)) {
      initFields()
    }
  }, [oil, fields, isLoading, initFields])

  return (
    <div className={styles.container}>
      {isLoading && <Loader />}
      <div className={styles.actions}>
        <RectangleButton title="Назад" theme="blue_border" onClick={handleBack} />
        <div className={styles.actions_right}>
          {!isEdit && <RectangleButton title="Редактировать" onClick={handleEdit} />}
          {isEdit && <RectangleButton title="Сохранить" onClick={handleSave} />}
          <RectangleButton title="Отмена" theme="red_border" onClick={handleCancel} />
        </div>
      </div>

      <hr />

      <div className={styles.body}>
        {isEdit && (
          <Editing
            fields={fields}
            file={file}
            onChangeInput={handleChangeInput}
            onUpload={handleUpload}
          />
        )}
        {!isEdit && <Viewing oil={oil} />}
      </div>
    </div>
  )
}

Oil.propTypes = {
  oil: PropTypes.shape({
    cover: PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    }),
    _id: PropTypes.string,
  }),
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  updateOil: PropTypes.func,
}

Oil.defaultProps = {
  oil: {},
  history: {
    goBack: noop,
  },
  updateOil: noop,
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(Oil)
