import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'

import profile from 'images/profile.png'
import Logout from 'icons/logout'
import IconSmallLogo from 'icons/smallLogo'
import IconLogo from 'icons/logo'
import IconMenu from 'icons/menu'

import { actionLogout } from 'store/auth/actions'
import { actionToggleSidebar } from 'store/ui/actions'
import { selectIsSidebarOpen } from 'store/ui/selectors'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  logout: actionLogout,
  toggleSidebar: actionToggleSidebar,
}

const mapStateToProps = (state) => ({
  isSidebarOpen: selectIsSidebarOpen(state),
})

const Header = ({ logout, toggleSidebar, isSidebarOpen }) => {
  const logoutHandler = useCallback(() => {
    logout()
  })

  const toggleSidebarHandler = useCallback(() => {
    toggleSidebar(!isSidebarOpen)
  }, [isSidebarOpen])

  return (
    <header>
      <div className={classNames(styles.left, { [styles.close]: !isSidebarOpen })}>
        <div className={styles.logo}>
          <NavLink activeClassName={styles.active} to={'/'} exact>
            {isSidebarOpen && <IconLogo size={140} />}
            {!isSidebarOpen && <IconSmallLogo size={36} />}
          </NavLink>
        </div>
      </div>
      <div className={styles.center}>
        <div className={styles.burger} onClick={toggleSidebarHandler}>
          <IconMenu size={30} />
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.profile}>
          <img src={profile} alt="profile" />
        </div>
        <div className={styles.logout} onClick={logoutHandler}>
          <Logout size={18} />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  logout: PropTypes.func,
  toggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool.isRequired,
}

Header.defaultProps = {
  logout: noop,
  toggleSidebar: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
