import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import App from 'containers/App'
import store from './store'
import 'styles/common.scss'

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ReduxToastr className="custom_toast" />
  </Provider>,
  document.getElementById('root')
)
