import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ClickOutside from 'react-outside-click-handler'
import classNames from 'classnames'
import noop from 'lodash/noop'

import IconArrowDown from 'icons/arrowDown'

import styles from './styles.module.scss'

const Select = ({ name, label, placeholder, items, selectedItem, onSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded)
  }

  const handleSelect = (value) => () => {
    onSelect(value, name)
    setIsExpanded(false)
  }

  return (
    <ClickOutside onOutsideClick={isExpanded ? toggleExpanded : noop}>
      <label>{label}</label>
      <div className={styles.selectContainer} onClick={toggleExpanded}>
        <span className={classNames(styles.title, { [styles.expanded]: isExpanded })}>
          {selectedItem.title || placeholder}
          <IconArrowDown size={12} />
        </span>

        {isExpanded && (
          <ul className={styles.list}>
            {items.map((item) => (
              <li
                key={item.id}
                className={classNames({ [styles.selected]: item.id === selectedItem.id })}
                onClick={handleSelect(item.value)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </ClickOutside>
  )
}

Select.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  selectedItem: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
  }),
  onSelect: PropTypes.func,
}

Select.defaultProps = {
  name: '',
  label: '',
  placeholder: '',
  items: [],
  selectedItem: {},
  onSelect: noop,
}

export default Select
