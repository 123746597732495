import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from 'containers/Login'
import Main from 'containers/Main'
import Testing from 'containers/Testing'
import Complited from 'containers/Complited'
import Oil from 'containers/Oil'
import ViewResult from 'containers/ViewResult'
import AddingOils from 'containers/AddingOils'
import AddingResults from 'containers/AddingResults'
import OilsList from 'containers/OilsList'
import Mixes from 'containers/Mixes'

export const ROUTES = {
  MAIN: '/',
  TESTING: '/testing',
  COMPLETED: '/completed',
  EDIT_OIL: '/oil/:id',
  VIEW_RESULT: '/result/:id',
  ADDING_OILS: '/adding_oils',
  ADDING_RESULTS: '/adding_results',
  LIST_OILS: '/list_oils',
  MIXES: '/mixes',
}

export const MENU = [
  { to: '/', title: 'Главная', iconName: 'HOME' },
  { to: '/testing', title: 'Тестирование', iconName: 'TESTING' },
  { to: '/completed', title: 'Пройденные тесты', iconName: 'COMPLITED' },
  { to: '/adding_oils', title: 'Добавление масел', iconName: 'ADDING_OILS' },
  { to: '/adding_results', title: 'Добавление результатов', iconName: 'ADDING_RESULTS' },
  { to: '/list_oils', title: 'Список масел', iconName: 'LIST_OILS' },
  { to: '/mixes', title: 'Смеси', iconName: 'MIXES' },
]

export const useRoutes = (isAuth) => {
  if (isAuth) {
    return (
      <>
        <Switch>
          <Route path={ROUTES.MAIN} component={Main} exact />
          <Route path={ROUTES.TESTING} component={Testing} exact />
          <Route path={ROUTES.COMPLETED} component={Complited} exact />
          <Route path={ROUTES.EDIT_OIL} component={Oil} exact />
          <Route path={ROUTES.VIEW_RESULT} component={ViewResult} exact />
          <Route path={ROUTES.ADDING_OILS} component={AddingOils} exact />
          <Route path={ROUTES.ADDING_RESULTS} component={AddingResults} exact />
          <Route path={ROUTES.LIST_OILS} component={OilsList} exact />
          <Route path={ROUTES.MIXES} component={Mixes} exact />
          <Redirect to={ROUTES.MAIN} />
        </Switch>
      </>
    )
  }

  return (
    <Switch>
      <Route path={ROUTES.MAIN} component={Login} exact />
      <Redirect to={ROUTES.MAIN} />
    </Switch>
  )
}
