import React from 'react'
import PropTypes from 'prop-types'

const AddingOils = ({ size, color, additionalColor }) => (
  <svg
    width={size}
    height={(20 * size) / 16}
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.27572 17.6617L2.35428 17.729H4.52181V17.1691H2.56258C1.28852 16.0403 0.559865 14.4253 0.559865 12.7218C0.559865 9.89115 2.57255 7.44026 5.34559 6.89413L5.57144 6.84966V5.35715H7.42863V6.8497L7.65448 6.89417C10.4275 7.4403 12.4402 9.89118 12.4402 12.7218C12.4402 14.4254 11.7116 16.0403 10.4375 17.1691H5.26832V17.729H10.6457L10.7242 17.6617C12.1705 16.4238 13 14.6233 13 12.7218C13 9.7017 10.9061 7.07669 7.98846 6.3935V4.22641C8.33547 4.16608 8.60012 3.86269 8.60012 3.49868V2.51063C8.60012 2.10332 8.2688 1.77196 7.86152 1.77196H7.67296L7.9092 0H5.09077L5.32707 1.77196H5.13851C4.73124 1.77196 4.39992 2.10332 4.39992 2.51063V3.49871C4.39992 3.86273 4.66457 4.16611 5.01158 4.22645V6.39354C2.09386 7.07669 0 9.70174 0 12.7218C0 14.6233 0.829453 16.4238 2.27572 17.6617ZM7.26974 0.559865L7.10809 1.77196H5.89191L5.73026 0.559865H7.26974V0.559865ZM4.95975 3.49868V2.51063C4.95975 2.41201 5.03993 2.33183 5.13848 2.33183H5.40173H7.59827H7.86152C7.96011 2.33183 8.04025 2.41204 8.04025 2.51063V3.49871C8.04025 3.59734 7.96007 3.67752 7.86152 3.67752H7.70853H7.42859H6.5V4.23738H7.42859V4.79725H5.57141V3.67756H5.13848C5.03996 3.67752 4.95975 3.5973 4.95975 3.49868Z"
      fill={color}
    />
    <path
      d="M9.9071 16.1313L10.3029 16.5273C11.1554 15.6755 11.7024 14.5482 11.8433 13.3531L11.2873 13.2876C11.161 14.3581 10.6709 15.3681 9.9071 16.1313Z"
      fill={color}
    />
    <path
      d="M10.6081 9.25003C9.8224 8.31916 8.73499 7.67757 7.54624 7.44347L7.43808 7.99277C8.50252 8.20238 9.47635 8.77716 10.1803 9.61118C10.883 10.4438 11.2872 11.499 11.3184 12.5825L11.8781 12.5664C11.8432 11.3568 11.3922 10.179 10.6081 9.25003Z"
      fill={color}
    />
    <circle cx="10.5" cy="14.5" r="5.5" fill={additionalColor} />
    <path
      d="M10.5 19C11.6935 19 12.8381 18.5259 13.682 17.682C14.5259 16.8381 15 15.6935 15 14.5C15 13.3065 14.5259 12.1619 13.682 11.318C12.8381 10.4741 11.6935 10 10.5 10C9.30653 10 8.16193 10.4741 7.31802 11.318C6.47411 12.1619 6 13.3065 6 14.5C6 15.6935 6.47411 16.8381 7.31802 17.682C8.16193 18.5259 9.30653 19 10.5 19V19ZM10.5 12C10.6326 12 10.7598 12.0527 10.8536 12.1464C10.9473 12.2402 11 12.3674 11 12.5V14H12.5C12.6326 14 12.7598 14.0527 12.8536 14.1464C12.9473 14.2402 13 14.3674 13 14.5C13 14.6326 12.9473 14.7598 12.8536 14.8536C12.7598 14.9473 12.6326 15 12.5 15H11V16.5C11 16.6326 10.9473 16.7598 10.8536 16.8536C10.7598 16.9473 10.6326 17 10.5 17C10.3674 17 10.2402 16.9473 10.1464 16.8536C10.0527 16.7598 10 16.6326 10 16.5V15H8.5C8.36739 15 8.24021 14.9473 8.14645 14.8536C8.05268 14.7598 8 14.6326 8 14.5C8 14.3674 8.05268 14.2402 8.14645 14.1464C8.24021 14.0527 8.36739 14 8.5 14H10V12.5C10 12.3674 10.0527 12.2402 10.1464 12.1464C10.2402 12.0527 10.3674 12 10.5 12Z"
      fill={color}
    />
  </svg>
)

AddingOils.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  additionalColor: PropTypes.string,
}

AddingOils.defaultProps = {
  size: 16,
  color: 'currentColor',
  additionalColor: '#2F3A4C',
}

export default AddingOils
