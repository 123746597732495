import React from 'react'
import PropTypes from 'prop-types'

const AddingResults = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.85399 3.146C5.90055 3.19245 5.9375 3.24762 5.9627 3.30837C5.98791 3.36911 6.00088 3.43423 6.00088 3.5C6.00088 3.56577 5.98791 3.63089 5.9627 3.69163C5.9375 3.75238 5.90055 3.80756 5.85399 3.854L3.85399 5.854C3.80755 5.90056 3.75237 5.93751 3.69162 5.96271C3.63088 5.98792 3.56576 6.00089 3.49999 6.00089C3.43422 6.00089 3.3691 5.98792 3.30836 5.96271C3.24761 5.93751 3.19244 5.90056 3.14599 5.854L2.14599 4.854C2.0521 4.76011 1.99936 4.63278 1.99936 4.5C1.99936 4.36723 2.0521 4.23989 2.14599 4.146C2.23988 4.05211 2.36722 3.99937 2.49999 3.99937C2.63277 3.99937 2.7601 4.05211 2.85399 4.146L3.49999 4.793L5.14599 3.146C5.19244 3.09944 5.24761 3.06249 5.30836 3.03729C5.3691 3.01208 5.43422 2.99911 5.49999 2.99911C5.56576 2.99911 5.63088 3.01208 5.69162 3.03729C5.75237 3.06249 5.80755 3.09944 5.85399 3.146Z"
      fill={color}
    />
    <path
      d="M5.85399 8.146C5.90055 8.19245 5.9375 8.24762 5.9627 8.30837C5.98791 8.36911 6.00088 8.43423 6.00088 8.5C6.00088 8.56577 5.98791 8.63089 5.9627 8.69163C5.9375 8.75238 5.90055 8.80756 5.85399 8.854L3.85399 10.854C3.80755 10.9006 3.75237 10.9375 3.69162 10.9627C3.63088 10.9879 3.56576 11.0009 3.49999 11.0009C3.43422 11.0009 3.3691 10.9879 3.30836 10.9627C3.24761 10.9375 3.19244 10.9006 3.14599 10.854L2.14599 9.854C2.0995 9.80751 2.06263 9.75232 2.03747 9.69158C2.01231 9.63085 1.99936 9.56574 1.99936 9.5C1.99936 9.43426 2.01231 9.36916 2.03747 9.30842C2.06263 9.24768 2.0995 9.19249 2.14599 9.146C2.23988 9.05211 2.36722 8.99937 2.49999 8.99937C2.56573 8.99937 2.63084 9.01232 2.69157 9.03748C2.75231 9.06264 2.8075 9.09951 2.85399 9.146L3.49999 9.793L5.14599 8.146C5.19244 8.09944 5.24761 8.0625 5.30836 8.03729C5.3691 8.01208 5.43422 7.99911 5.49999 7.99911C5.56576 7.99911 5.63088 8.01208 5.69162 8.03729C5.75237 8.0625 5.80755 8.09944 5.85399 8.146Z"
      fill={color}
    />
    <path
      d="M7 4.5C7 4.36739 7.05268 4.24021 7.14645 4.14645C7.24021 4.05268 7.36739 4 7.5 4H10.5C10.6326 4 10.7598 4.05268 10.8536 4.14645C10.9473 4.24021 11 4.36739 11 4.5C11 4.63261 10.9473 4.75979 10.8536 4.85355C10.7598 4.94732 10.6326 5 10.5 5H7.5C7.36739 5 7.24021 4.94732 7.14645 4.85355C7.05268 4.75979 7 4.63261 7 4.5Z"
      fill={color}
    />
    <path
      d="M2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V11.5C0 12.163 0.263392 12.7989 0.732233 13.2678C1.20107 13.7366 1.83696 14 2.5 14H6.6C6.43647 13.6804 6.30483 13.3454 6.207 13H2.5C2.10218 13 1.72064 12.842 1.43934 12.5607C1.15804 12.2794 1 11.8978 1 11.5V2.5C1 2.10218 1.15804 1.72064 1.43934 1.43934C1.72064 1.15804 2.10218 1 2.5 1H11.5C11.8978 1 12.2794 1.15804 12.5607 1.43934C12.842 1.72064 13 2.10218 13 2.5V6.207C13.349 6.306 13.683 6.437 14 6.6V2.5C14 1.83696 13.7366 1.20107 13.2678 0.732233C12.7989 0.263392 12.163 0 11.5 0H2.5Z"
      fill={color}
    />
    <path
      d="M11.5 16C12.6935 16 13.8381 15.5259 14.682 14.682C15.5259 13.8381 16 12.6935 16 11.5C16 10.3065 15.5259 9.16193 14.682 8.31802C13.8381 7.47411 12.6935 7 11.5 7C10.3065 7 9.16193 7.47411 8.31802 8.31802C7.47411 9.16193 7 10.3065 7 11.5C7 12.6935 7.47411 13.8381 8.31802 14.682C9.16193 15.5259 10.3065 16 11.5 16ZM11.5 9C11.6326 9 11.7598 9.05268 11.8536 9.14645C11.9473 9.24021 12 9.36739 12 9.5V11H13.5C13.6326 11 13.7598 11.0527 13.8536 11.1464C13.9473 11.2402 14 11.3674 14 11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H12V13.5C12 13.6326 11.9473 13.7598 11.8536 13.8536C11.7598 13.9473 11.6326 14 11.5 14C11.3674 14 11.2402 13.9473 11.1464 13.8536C11.0527 13.7598 11 13.6326 11 13.5V12H9.5C9.36739 12 9.24021 11.9473 9.14645 11.8536C9.05268 11.7598 9 11.6326 9 11.5C9 11.3674 9.05268 11.2402 9.14645 11.1464C9.24021 11.0527 9.36739 11 9.5 11H11V9.5C11 9.36739 11.0527 9.24021 11.1464 9.14645C11.2402 9.05268 11.3674 9 11.5 9Z"
      fill={color}
    />
  </svg>
)

AddingResults.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

AddingResults.defaultProps = {
  size: 16,
  color: 'currentColor',
}

export default AddingResults
