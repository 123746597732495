import { call, takeEvery, put } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'

import { createOil, loadOils, createCover, deleteOil, updateOil, updateCover } from 'api/oil'
import { actionShowLoading, actionHideLoading } from 'store/ui/actions'

import {
  actionCreateOilRequest,
  actionCreateOilSuccess,
  actionLoadOilsRequest,
  actionLoadOilsSuccess,
  actionDeleteOilRequest,
  actionDeleteOilSuccess,
  actionUpdateOilRequest,
  actionUpdateOilSuccess,
} from './actions'

function* loadOilsSaga() {
  yield put(actionShowLoading())

  const result = yield call(loadOils)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  yield put(actionLoadOilsSuccess(result.data))
  yield put(actionHideLoading())
}

function* createOilSaga({ payload }) {
  yield put(actionShowLoading())

  const { fields, file } = payload
  const resultCover = file ? yield call(createCover, file) : {}

  if (resultCover.error) {
    toastr.error(resultCover.error.message || 'Не удалось загрузить обложку')
    yield put(actionHideLoading())
    return
  }

  const result = yield call(createOil, {
    ...fields,
    ...(resultCover.data ? { cover: { ...resultCover.data } } : {}),
  })

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  window.location.reload()
  yield put(actionCreateOilSuccess(result.data))
  yield put(actionHideLoading())
}

function* removeOilSaga({ payload }) {
  yield put(actionShowLoading())

  const { id } = payload
  const result = yield call(deleteOil, id)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  yield put(actionDeleteOilSuccess(id))
  yield put(actionHideLoading())
}

function* updateOilSaga({ payload }) {
  yield put(actionShowLoading())

  const { id, fields, file } = payload

  const resultCover = file ? yield call(updateCover, id, file) : {}

  if (resultCover.error) {
    toastr.error(resultCover.error.message || 'Не удалось загрузить обложку')
    yield put(actionHideLoading())
    return
  }

  const data = {
    ...fields,
    ...(resultCover.data ? { cover: { ...resultCover.data } } : {}),
  }

  const result = yield call(updateOil, id, data)

  if (result.error) {
    toastr.error(result.error.message || 'Не удалось обновить масло')
    yield put(actionHideLoading())
    return
  }

  yield put(actionUpdateOilSuccess(result.data))
  yield put(actionHideLoading())
}

function* watchLoadOils() {
  yield takeEvery(actionLoadOilsRequest, loadOilsSaga)
}

function* watchCreateOil() {
  yield takeEvery(actionCreateOilRequest, createOilSaga)
}

function* watchRemoveOil() {
  yield takeEvery(actionDeleteOilRequest, removeOilSaga)
}

function* watchUpdateOil() {
  yield takeEvery(actionUpdateOilRequest, updateOilSaga)
}

export default [watchCreateOil(), watchLoadOils(), watchRemoveOil(), watchUpdateOil()]
