import React from 'react'
import PropTypes from 'prop-types'

const ArrowDown = ({ size, color }) => (
  <svg
    width={size}
    height={(8 * size) / 15}
    viewBox="0 0 15 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.00125 7.00125C6.7676 7.00171 6.54116 6.92033 6.36125 6.77125L0.361252 1.77125C0.157036 1.60151 0.0286112 1.3576 0.0042315 1.09318C-0.0201482 0.82875 0.0615137 0.565469 0.231252 0.361252C0.400991 0.157035 0.644902 0.0286112 0.909329 0.00423152C1.17375 -0.0201482 1.43704 0.0615132 1.64125 0.231252L7.00125 4.71125L12.3613 0.391253C12.4635 0.308187 12.5812 0.246155 12.7076 0.208723C12.8339 0.171291 12.9664 0.159198 13.0974 0.173137C13.2285 0.187076 13.3554 0.226773 13.4711 0.289947C13.5867 0.35312 13.6887 0.438526 13.7713 0.541252C13.8628 0.644074 13.9322 0.7647 13.975 0.895574C14.0178 1.02645 14.0331 1.16475 14.0199 1.30181C14.0068 1.43887 13.9655 1.57175 13.8986 1.6921C13.8317 1.81246 13.7407 1.91771 13.6313 2.00125L7.63125 6.83125C7.44617 6.95677 7.22435 7.01662 7.00125 7.00125V7.00125Z"
      fill={color}
    />
  </svg>
)

ArrowDown.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

ArrowDown.defaultProps = {
  size: 15,
  color: 'currentColor',
}

export default ArrowDown
