import { call, takeEvery, put } from 'redux-saga/effects'
import Cookies from 'js-cookie'
import { toastr } from 'react-redux-toastr'

import { authenticate } from 'api/auth'
import { actionShowLoading, actionHideLoading } from 'store/ui/actions'

import { actionAuthRequest, actionAuthSuccess, actionAuthClear, actionLogout } from './actions'

function* auth({ payload }) {
  yield put(actionShowLoading())

  const { email, password } = payload

  const result = yield call(authenticate, email, password)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  Cookies.set('token', result.data.token)
  Cookies.set('userId', result.data.userId)

  yield put(actionHideLoading())
  yield put(actionAuthSuccess(result.data))
}

function* logout() {
  yield put(actionAuthClear())

  Cookies.remove('token')
  Cookies.remove('userId')
}

function* watchAuth() {
  yield takeEvery(actionAuthRequest, auth)
}

function* watchLogout() {
  yield takeEvery(actionLogout, logout)
}

export default [watchAuth(), watchLogout()]
