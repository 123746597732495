import { createSelector } from 'reselect'

export const selectOils = (state) => state.oils

export const selectOilsEntities = createSelector(selectOils, (oils = {}) => oils.entities)
export const selectOilById = createSelector(
  selectOils,
  (state, props) => props,
  (oils = {}, props) => oils.entities.find((i) => i._id === props.id)
)
