import { GROUP_OILS_ITEMS_MAP } from 'utils/constants'

export const sortingByGroup = (items = []) => {
  return items.sort((a, b) => {
    const groupA = GROUP_OILS_ITEMS_MAP[a.group]
    const groupB = GROUP_OILS_ITEMS_MAP[b.group]

    if (!groupA) {
      return 1
    }

    if (!groupB) {
      return -1
    }

    if (groupA.id < groupB.id) {
      return -1
    }

    if (groupA.id > groupB.id) {
      return 1
    }

    return 0
  })
}
