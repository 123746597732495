import React from 'react'
import PropTypes from 'prop-types'

const Copyright = ({ size, color }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.88 7.14C11.16 7.2 11.49 8.29 11.51 8.8H13.3C13.22 6.82 11.81 5.61 9.85 5.61C7.64 5.61 6 7 6 10.14C6 12.08 6.93 14.38 9.84 14.38C12.06 14.38 13.25 12.73 13.28 11.43H11.49C11.46 12.02 11.04 12.81 9.86 12.87C8.55 12.83 8 11.81 8 10.14C8 7.25 9.28 7.16 9.88 7.14ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
      fill={color}
    />
  </svg>
)

Copyright.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Copyright.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default Copyright
