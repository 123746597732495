import { makeRequest } from 'utils/request'

export const authenticate = (email, password) =>
  makeRequest({
    url: '/api/auth/login',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: {
      email,
      password,
    },
  })
