import { HIDDEN_TEST_ANSWERS } from 'utils/constants'

export const getStringResultDescription = (result, oil) => {
  if (result === 'minus') {
    return `
      <p><span>Минус:&nbsp;</span>${oil[result]}<p>
      <br />
    `
  }

  if (result === 'double_minus') {
    return `
      <p><span>Вопрос:&nbsp;</span>${oil.question}<p>
      <br />
      <p><span>Минус:&nbsp;</span>${oil.minus}<p>
      <br />
      <p><span>Общее:&nbsp;</span>${oil.general}<p>
      <br />
    `
  }

  if (result === 'plus') {
    return `
      <p><span>Плюс:&nbsp;</span>${oil[result]}<p>
      <br />
    `
  }

  if (result === 'double_plus') {
    return `
      <p><span>Плюс:&nbsp;</span>${oil.plus}<p>
      <br />
      <p><span>Общее:&nbsp;</span>${oil.general}<p>
      <br />
    `
  }
}

export const createDescription = ({ plus, minus, general, question }) => {
  let description = ''

  if (question) {
    description += `
      <p><span>Вопрос:&nbsp;</span>${question}<p>
      <br />
    `
  }

  if (plus) {
    description += `
      <p><span>Плюс:&nbsp;</span>${plus}<p>
      <br />
    `
  }

  if (minus) {
    description += `
      <p><span>Минус:&nbsp;</span>${minus}<p>
      <br />
    `
  }

  if (general) {
    description += `
      <p><span>Общее:&nbsp;</span>${general}<p>
      <br />
    `
  }

  return description
}

export const preparationResult = (result) => {
  const preparedResult = { ...result }

  preparedResult.create_at = new Date(result.create_at).toISOString()
  preparedResult.test_result = preparedResult.test_result.map(({ title, en_title, ...rest }) => ({
    title,
    en_title,
    description: createDescription(rest),
  }))

  return preparedResult
}

export const preparationResultForSendMail = (results) =>
  results
    .filter((result) => !!result.description)
    .filter((result) => !HIDDEN_TEST_ANSWERS.includes(result.answer))
