import { makeRequest } from 'utils/request'

export const loadOils = () =>
  makeRequest({
    url: '/api/oils',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
  })

export const createOil = (data) =>
  makeRequest({
    url: '/api/oils',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })

export const updateOil = (id, data) =>
  makeRequest({
    url: `/api/oils/${id}`,
    method: 'patch',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })

export const createCover = (file) => {
  const form = new FormData()
  form.append('file', file)

  return makeRequest({
    url: '/api/oils/cover',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: form,
  })
}

export const updateCover = (id, file) => {
  const form = new FormData()
  form.append('file', file)

  return makeRequest({
    url: `/api/oils/${id}/cover`,
    method: 'patch',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data: form,
  })
}

export const deleteOil = (id) =>
  makeRequest({
    url: `/api/oils/${id}`,
    method: 'delete',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
  })
