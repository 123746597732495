export const parseDateToLocationString = (date) => {
  if (!date) {
    return new Date().toLocaleDateString()
  }

  return new Date(date).toLocaleDateString()
}

export const getIdFormPathName = () => {
  const pathnameArray = window.location.pathname.split('/')
  return pathnameArray[pathnameArray.length - 1]
}
