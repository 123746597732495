import React, { useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import classNames from 'classnames'
import { isEdge, isChrome, isFirefox, osName } from 'react-device-detect'

import styles from './styles.module.scss'

const ButtonTag = ({ children, type, ...props }) =>
  type === 'file' ? (
    <div {...props}>{children}</div>
  ) : (
    <button type={type} {...props}>
      {children}
    </button>
  )

const RectangleButton = ({
  title,
  type,
  onClick,
  icon,
  renderIcon,
  disabled,
  accept,
  theme,
  externalStyle,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const mouseEnterHandler = () => setIsHovered(true)
  const mouseLeaveHandler = () => setIsHovered(false)
  // Следующие два метода нужны для корректной работы input type=file
  // в браузерах Edge, Chrome (Mac Os) и Firefox
  const addEventChangeHandler = (e) => {
    onClick(e)
    e.target.removeEventListener('change', addEventChangeHandler)
  }
  const clickForEdgeHandler = (e) => {
    e.target.addEventListener('change', addEventChangeHandler)
  }
  const isMacOS = osName === 'Mac OS'
  const isChromeMacOS = isChrome && isMacOS

  return (
    <ButtonTag
      type={type}
      onClick={type === 'file' || disabled ? noop : onClick}
      onMouseEnter={mouseEnterHandler}
      onMouseLeave={mouseLeaveHandler}
      disabled={disabled}
      className={classNames(externalStyle, {
        [styles.button]: true,
        [styles[theme]]: true,
      })}
    >
      {type === 'file' && (
        <>
          <input
            id="file"
            type="file"
            className={styles.inputFile}
            onChange={onClick}
            onClick={isEdge || isChromeMacOS || isFirefox ? clickForEdgeHandler : noop}
            multiple
            accept={accept}
          />
          <label htmlFor="file">{title || icon}</label>
        </>
      )}
      {type !== 'file' && (
        <>
          {renderIcon && renderIcon(isHovered)}
          {!renderIcon && icon && icon}
          {title}
        </>
      )}
    </ButtonTag>
  )
}

RectangleButton.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.func,
  renderIcon: PropTypes.func,
  icon: PropTypes.node,
  disabled: PropTypes.bool,
  theme: PropTypes.string,
  accept: PropTypes.string,
  externalStyle: PropTypes.string,
  children: PropTypes.node,
  props: PropTypes.shape({}),
}

RectangleButton.defaultProps = {
  title: '',
  type: 'button',
  onClick: noop,
  renderIcon: undefined,
  icon: null,
  disabled: false,
  theme: 'blue',
  accept: '',
  externalStyle: '',
  children: null,
  props: {},
}

ButtonTag.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.node.isRequired,
}

export default RectangleButton
