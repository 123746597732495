import React from 'react'
import PropTypes from 'prop-types'

const AddingOils = ({ size, color }) => (
  <svg
    width={size}
    height={(19 * size) / 16}
    viewBox="0 0 16 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 13.2063L11.1746 18.0317L8.38095 14.9841L9.55937 13.8057L11.1746 15.421L14.8216 11.774L16 13.2063ZM11.8044 8.88889C10.4737 4.92698 6.09524 0 6.09524 0C6.09524 0 0 6.85714 0 10.9206C0 14.2832 2.7327 17.0159 6.09524 17.0159H6.44064C6.21714 16.3657 6.09524 15.6952 6.09524 14.9841C6.09524 11.7537 8.63492 9.11238 11.8044 8.88889Z"
      fill={color}
    />
  </svg>
)

AddingOils.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

AddingOils.defaultProps = {
  size: 16,
  color: 'currentColor',
}

export default AddingOils
