import React from 'react'
import PropTypes from 'prop-types'

const Mixes = ({ size, color }) => (
  <svg
    width={size}
    height={(15 * size) / 16}
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.88 6.08H0V8.48C0 11.6 2.48 14.08 5.6 14.08H10.4C13.52 14.08 16 11.6 16 8.48V6.08H10.88ZM14.4 8.48C14.4 10.72 12.64 12.48 10.4 12.48H5.6C3.36 12.48 1.6 10.72 1.6 8.48V7.68H14.4V8.48ZM11.36 5.28L14.64 0L16 0.88L13.28 5.28H11.36Z"
      fill={color}
    />
  </svg>
)

Mixes.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Mixes.defaultProps = {
  size: 16,
  color: 'currentColor',
}

export default Mixes
