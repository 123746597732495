import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import get from 'lodash/get'
import noop from 'lodash/noop'

import { RADIO_ITEMS_TEST } from 'utils/constants'

import TestIcon from 'icons/test'
import RectangleButton from 'components/RectangleButton'
import RadioButton from 'components/RadioButton'

import styles from './styles.module.scss'

const Test = ({ oils, testResult, onCancel, onGetResult, onBack }) => {
  const [testingCard, setTestingCard] = useState(testResult || {})

  const handleChangeValue = useCallback(
    (e) => {
      const { name, value } = e.target
      setTestingCard({ ...testingCard, [name]: value })
    },
    [testingCard]
  )

  const handleGetResult = useCallback(() => {
    if (!Object.keys(testingCard).length) {
      return toastr.error('Не отмечен ни один вариант')
    }

    onGetResult(testingCard)
  }, [onGetResult, testingCard, oils])

  return (
    <>
      <div className={styles.title}>
        <TestIcon size={26} />
        Тестирование
        <RectangleButton title="Назад" onClick={onBack} />
      </div>
      {oils.length === 0 && <div className={styles.empty}>Список масел пуст</div>}
      {oils.length > 0 && (
        <div className={styles.list}>
          {oils.map((oil) => (
            <div key={oil._id} className={styles.item}>
              <div className={styles.preview}>
                <img src={`/${get(oil, 'cover.path')}`} alt={get(oil, 'cover.name')} />
              </div>
              <div className={styles.controls}>
                <RadioButton
                  items={RADIO_ITEMS_TEST}
                  onChange={handleChangeValue}
                  name={oil._id}
                  radioClassName={styles.radio}
                  selectedItem={testingCard[oil._id] || ''}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className={styles.buttons}>
        {oils.length > 0 && (
          <RectangleButton title="Получить результат" onClick={handleGetResult} />
        )}
        <RectangleButton title="Отмена" theme="red_border" onClick={onCancel} />
      </div>
    </>
  )
}

Test.propTypes = {
  oils: PropTypes.arrayOf(PropTypes.shape({})),
  testResult: PropTypes.shape({}),
  onGetResult: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
}

Test.defaultProps = {
  oils: [],
  testResult: null,
  onGetResult: noop,
  onCancel: noop,
  onBack: noop,
}

export default Test
