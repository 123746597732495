import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { withRouter } from 'react-router-dom'
import classNames from 'classnames'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'

import { getIdFormPathName } from 'utils/utils'
import { HIDDEN_TEST_ANSWERS } from 'utils/constants'
import { sortingByGroup } from 'utils/sorting'

import RectangleButton from 'components/RectangleButton'
import { preparationResultForSendMail } from 'containers/Testing/utils'

import { selectResultByIdWithOilsGroup } from 'store/results/selectors'
import { actionSendResultsRequest } from 'store/results/actions'

import styles from './styles.module.scss'

const mapStateToProps = (state) => ({
  result: selectResultByIdWithOilsGroup(state, { id: getIdFormPathName() }),
})

const mapDispatchToProps = {
  sendResult: actionSendResultsRequest,
}

const ViewResult = ({ result, history, sendResult }) => {
  const [expandedBlocksTitle, setExpandedBlockId] = useState([])
  const [isSortingByGroup, setIsSortingByGroup] = useState(false)

  const toggleExpanded = useCallback(
    (title) => () => {
      const newExpandedBlocksTitle = expandedBlocksTitle.includes(title)
        ? expandedBlocksTitle.filter((i) => i !== title)
        : [...expandedBlocksTitle, title]
      setExpandedBlockId(newExpandedBlocksTitle)
    },
    [expandedBlocksTitle]
  )

  const getSortedResultTest = useMemo(() => {
    const testResult = !isEmpty(result) ? [...result.test_result] : []
    return isSortingByGroup ? sortingByGroup(testResult) : testResult
  }, [isSortingByGroup, result])

  const handleBack = useCallback(() => {
    history.goBack()
  }, [history])

  const handleSend = useCallback(() => {
    const resultForSendMail = preparationResultForSendMail(result.test_result)
    const { email } = result

    sendResult({ email, resultForSendMail })
  }, [sendResult, result])

  const handleCheckbox = useCallback(
    (e) => {
      const { checked } = e.target
      setIsSortingByGroup(Boolean(checked))
    },
    [result]
  )

  const renderResults = (item) => {
    if (HIDDEN_TEST_ANSWERS.includes(item.answer)) {
      return (
        <div key={item.en_title} className={styles.item}>
          <div className={styles.title} onClick={toggleExpanded(item.en_title)}>
            {item.title}
          </div>
          <div
            className={classNames(styles.contentExpanded, {
              [styles.expanded]: expandedBlocksTitle.includes(item.en_title),
            })}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      )
    }

    return (
      <div key={item.en_title} className={styles.item}>
        <div className={styles.title}>{item.title}</div>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: item.description }} />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>Просмотр результата теста</div>
      <div className={styles.actions}>
        <RectangleButton title="Назад" theme="blue_border" onClick={handleBack} />
        <RectangleButton title="Отправить" theme="blue" onClick={handleSend} />
      </div>
      <div className={styles.field}>
        <span>Имя:</span>
        {result.first_name}
      </div>
      <div className={styles.field}>
        <span>Фамилия:</span>
        {result.last_name}
      </div>
      <div className={styles.field}>
        <span>Email:</span>
        {result.email}
      </div>
      <div className={styles.field}>
        <span>Сортировать по группам</span>
        <input type="checkbox" name="group" id="group" onChange={handleCheckbox} />
      </div>
      <div className={styles.result}>{getSortedResultTest.map(renderResults)}</div>
    </div>
  )
}

ViewResult.propTypes = {
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }),
  result: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    email: PropTypes.string,
    test_result: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  sendResult: PropTypes.func,
}

ViewResult.defaultProps = {
  history: {},
  result: {
    first_name: '',
    last_name: '',
    email: '',
    test_result: [],
    sendResult: noop,
  },
}

export default compose(connect(mapStateToProps, mapDispatchToProps), withRouter)(ViewResult)
