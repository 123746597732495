import React from 'react'
import PropTypes from 'prop-types'

const Results = ({ size, color }) => (
  <svg
    width={size}
    height={(16 * size) / 14}
    viewBox="0 0 14 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2941 1.41176V0H0V14.1176H1.41177V16H13.1765V1.41176H11.2941ZM0.941178 0.941177H10.353V13.1765H0.941178V0.941177ZM12.2353 15.0588H2.35294V14.1176H11.2941V2.35294H12.2353V15.0588Z"
      fill={color}
    />
    <path
      d="M5.64706 6.58825C6.68499 6.58825 7.52942 5.74382 7.52942 4.70589C7.52942 3.76911 6.30551 2.52901 5.64706 1.88195C5.59941 1.94234 3.76471 3.57896 3.76471 4.70589C3.76471 5.74382 4.60913 6.58825 5.64706 6.58825ZM5.64725 3.22241C6.14542 3.77673 6.58824 4.42002 6.58824 4.70589C6.58824 5.22486 6.16603 5.64707 5.64706 5.64707C5.1281 5.64707 4.70589 5.22486 4.70589 4.70589C4.70589 4.42093 5.14899 3.77736 5.64725 3.22241Z"
      fill={color}
    />
    <path d="M2.35297 7.52942H8.94121V8.47059H2.35297V7.52942Z" fill={color} />
    <path d="M2.35297 9.41179H8.94121V10.3529H2.35297V9.41179Z" fill={color} />
    <path d="M2.35297 11.2941H8.94121V12.2353H2.35297V11.2941Z" fill={color} />
  </svg>
)

Results.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  additionalColor: PropTypes.string,
}

Results.defaultProps = {
  size: 14,
  color: 'currentColor',
  additionalColor: '#2F3A4C',
}

export default Results
