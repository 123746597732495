import React from 'react'
import styles from './styles.module.scss'

const Loader = () => (
  <div className={styles.wrapper}>
    <div className={styles.loader}></div>
    <div className={styles.overlay} />
  </div>
)

export default Loader
