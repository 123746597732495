import React from 'react'
import PropTypes from 'prop-types'

const SmallLogo = ({ size, color }) => (
  <svg
    width={size}
    height={(25 * size) / 33}
    viewBox="0 0 33 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.28 5.664C7.59467 7.776 6.37867 9.888 5.632 12H5.664C8.62933 12 10.3253 12.0107 10.752 12.032C11.2 12.032 11.4453 12.0107 11.488 11.968C11.5307 11.9253 11.5627 11.872 11.584 11.808C11.2213 9.184 10.9333 7.40267 10.72 6.464C10.5067 5.504 10.2933 5.024 10.08 5.024C9.86667 5.024 9.6 5.23733 9.28 5.664ZM6.432 20.736L7.136 20.608C7.34933 20.608 7.456 20.7253 7.456 20.96C7.456 21.7067 7.30667 22.336 7.008 22.848C6.70933 23.36 6.336 23.7227 5.888 23.936C5.16267 24.2987 4.544 24.48 4.032 24.48C3.05067 24.48 2.31467 24.1173 1.824 23.392C1.33333 22.6453 1.088 21.536 1.088 20.064C1.088 18.5707 1.49333 16.64 2.304 14.272H0.448C0.213333 14.272 0.096 14.1547 0.096 13.92C0.096 13.6853 0.277333 13.312 0.64 12.8C1.00267 12.2667 1.38667 12 1.792 12H3.232C4.74667 8.62933 6.528 5.71733 8.576 3.264C9.10933 2.624 9.76 2.02667 10.528 1.472C11.3173 0.917332 11.9253 0.639999 12.352 0.639999C12.7787 0.639999 13.0987 0.831999 13.312 1.216C13.8667 2.112 14.5173 5.38667 15.264 11.04C15.52 13.472 15.9573 15.9893 16.576 18.592C17.216 21.1733 17.8027 22.6773 18.336 23.104C18.464 23.1893 18.528 23.296 18.528 23.424C18.528 23.744 18.0693 24.0427 17.152 24.32C16.256 24.5973 15.5627 24.736 15.072 24.736C14.6027 24.736 14.24 24.672 13.984 24.544C13.728 24.4373 13.568 24.32 13.504 24.192C13.12 23.36 12.736 21.376 12.352 18.24L12.16 16.736C12.0747 15.7333 11.9893 14.976 11.904 14.464L11.68 14.304L4.992 14.272C4.736 15.36 4.608 16.3093 4.608 17.12C4.608 17.9307 4.62933 18.528 4.672 18.912C4.736 19.2747 4.91733 19.6693 5.216 20.096C5.51467 20.5227 5.92 20.736 6.432 20.736Z"
      fill={color}
    />
    <path
      d="M22.456 1.216L27.064 1.472C29.112 1.472 30.584 1.22666 31.48 0.735998C31.608 0.650666 31.7147 0.608 31.8 0.608C31.9067 0.608 31.96 0.757333 31.96 1.056C31.96 1.35467 31.7787 1.84533 31.416 2.528C30.8827 3.57333 30.1147 4.096 29.112 4.096L27.352 4.064C27.1813 4.064 27.0747 4.16 27.032 4.352C26.8827 7.488 26.808 10.88 26.808 14.528C26.808 18.1547 26.9573 20.8853 27.256 22.72V22.88C27.256 23.4133 26.744 23.84 25.72 24.16C24.7173 24.48 23.768 24.64 22.872 24.64C22.4453 24.64 22.232 24.5227 22.232 24.288C22.232 24.224 22.264 24.0747 22.328 23.84C22.5627 23.3707 22.7653 21.0987 22.936 17.024C23.128 12.9493 23.224 9.87733 23.224 7.808L23.16 4.32C23.16 4.192 23.096 4.11733 22.968 4.096C22.52 4.01067 22.008 3.968 21.432 3.968C20.28 3.968 19.4267 4.20267 18.872 4.672C18.7227 4.77867 18.584 4.832 18.456 4.832C18.3067 4.832 18.232 4.74667 18.232 4.576C18.232 4.32 18.3707 3.968 18.648 3.52C18.904 3.072 19.1813 2.688 19.48 2.368C20.2053 1.6 21.1973 1.216 22.456 1.216Z"
      fill={color}
    />
  </svg>
)

SmallLogo.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

SmallLogo.defaultProps = {
  size: 33,
  color: 'currentColor',
}

export default SmallLogo
