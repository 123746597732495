import React from 'react'
import PropTypes from 'prop-types'

const Trash = ({ size, color }) => (
  <svg
    width={size}
    height={(32 * size) / 25}
    viewBox="0 0 25 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5476 27.9286C2.5476 29.8143 4.09046 31.3571 5.97617 31.3571H19.6905C21.5762 31.3571 23.119 29.8143 23.119 27.9286V7.35714H2.5476V27.9286ZM7.98188 16.94C7.66135 16.6195 7.48128 16.1847 7.48128 15.7314C7.48128 15.2781 7.66135 14.8434 7.98188 14.5229C8.30242 14.2023 8.73715 14.0223 9.19046 14.0223C9.64376 14.0223 10.0785 14.2023 10.399 14.5229L12.8333 16.94L15.2505 14.5229C15.571 14.2023 16.0057 14.0223 16.459 14.0223C16.9123 14.0223 17.3471 14.2023 17.6676 14.5229C17.9881 14.8434 18.1682 15.2781 18.1682 15.7314C18.1682 16.1847 17.9881 16.6195 17.6676 16.94L15.2505 19.3571L17.6676 21.7743C17.8263 21.933 17.9522 22.1214 18.0381 22.3288C18.124 22.5362 18.1682 22.7584 18.1682 22.9829C18.1682 23.2073 18.124 23.4296 18.0381 23.6369C17.9522 23.8443 17.8263 24.0327 17.6676 24.1914C17.5089 24.3501 17.3205 24.476 17.1131 24.5619C16.9057 24.6478 16.6835 24.692 16.459 24.692C16.2346 24.692 16.0123 24.6478 15.805 24.5619C15.5976 24.476 15.4092 24.3501 15.2505 24.1914L12.8333 21.7743L10.4162 24.1914C10.2575 24.3501 10.069 24.476 9.86167 24.5619C9.65431 24.6478 9.43205 24.692 9.2076 24.692C8.98315 24.692 8.76089 24.6478 8.55353 24.5619C8.34616 24.476 8.15774 24.3501 7.99903 24.1914C7.84032 24.0327 7.71442 23.8443 7.62852 23.6369C7.54263 23.4296 7.49842 23.2073 7.49842 22.9829C7.49842 22.7584 7.54263 22.5362 7.62852 22.3288C7.71442 22.1214 7.84032 21.933 7.99903 21.7743L10.4162 19.3571L7.98188 16.94ZM23.119 2.21429H18.8333L17.6162 0.997143C17.3076 0.688571 16.8619 0.5 16.4162 0.5H9.25046C8.80474 0.5 8.35903 0.688571 8.05046 0.997143L6.83331 2.21429H2.5476C1.60474 2.21429 0.833313 2.98571 0.833313 3.92857C0.833313 4.87143 1.60474 5.64286 2.5476 5.64286H23.119C24.0619 5.64286 24.8333 4.87143 24.8333 3.92857C24.8333 2.98571 24.0619 2.21429 23.119 2.21429Z"
      fill={color}
    />
  </svg>
)

Trash.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Trash.defaultProps = {
  size: 25,
  color: 'currentColor',
}

export default Trash
