import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'

import {
  GROUP_OILS_ITEMS,
  GROUP_OILS_ITEMS_MAP,
  VOLATILITY_OILS_ITEMS,
  VOLATILITY_OILS_ITEMS_MAP,
} from 'utils/constants'

import Input from 'components/Input'
import TextArea from 'components/Textarea'
import Select from 'components/Select'

import styles from './styles.module.scss'

const Editing = ({ fields, file, onChangeInput, onUpload }) => {
  const getSrc = useCallback(() => {
    if (file) {
      return file.fileURL
    }

    return `/${get(fields, 'cover.path')}`
  }, [file, fields])

  const handleChangeInput = useCallback(
    (e) => {
      const { name, value } = e.target
      onChangeInput(name, value)
    },
    [onChangeInput]
  )

  const handleSelect = useCallback(
    (value, name) => {
      onChangeInput(name, value)
    },
    [onChangeInput]
  )

  return (
    <>
      <div className={styles.preview}>
        <img src={getSrc()} alt={get(fields, 'cover.name')} />
        <div className={styles.cover__upload}>
          <input
            className={styles.inputfile}
            id="avatar"
            type="file"
            name="avatar"
            onChange={onUpload}
            accept="image/*"
          />
          <label htmlFor="avatar"> Загрузить</label>
        </div>
      </div>
      <div className={styles.fields}>
        <Input
          name="title"
          label="Название*"
          placeholder="Введите название..."
          handleChange={handleChangeInput}
          value={fields.title}
        />
        <div className={styles.groupFieldsContainer}>
          <Select
            name="group"
            label="Группа"
            items={GROUP_OILS_ITEMS}
            placeholder="Выберите группу"
            onSelect={handleSelect}
            selectedItem={GROUP_OILS_ITEMS_MAP[fields.group]}
          />
          <Select
            name="volatility"
            label="Летучесть"
            items={VOLATILITY_OILS_ITEMS}
            placeholder="Выберите летучесть"
            onSelect={handleSelect}
            selectedItem={VOLATILITY_OILS_ITEMS_MAP[fields.volatility]}
          />
        </div>
        <TextArea name="plus" label="Плюс*" onChange={handleChangeInput} value={fields.plus} />
        <TextArea name="minus" label="Минус*" onChange={handleChangeInput} value={fields.minus} />
        <TextArea
          name="general"
          label="Общее*"
          onChange={handleChangeInput}
          value={fields.general}
        />
        <TextArea
          name="question"
          label="Вопрос*"
          onChange={handleChangeInput}
          value={fields.question}
        />
      </div>
    </>
  )
}

Editing.propTypes = {
  fields: PropTypes.shape({
    cover: PropTypes.shape({
      path: PropTypes.string,
      name: PropTypes.string,
    }),
    title: PropTypes.string,
    plus: PropTypes.string,
    minus: PropTypes.string,
    general: PropTypes.string,
    question: PropTypes.string,
    group: PropTypes.string,
    volatility: PropTypes.string,
  }),
  onChangeInput: PropTypes.func,
  onUpload: PropTypes.func,
  file: PropTypes.shape({
    fileURL: PropTypes.string,
  }),
}

Editing.defaultProps = {
  fields: {
    cover: {
      path: '',
      name: '',
    },
    title: '',
    plus: '',
    minus: '',
    general: '',
    question: '',
    group: '',
    volatility: '',
  },
  onChangeInput: noop,
  onUpload: noop,
  file: null,
}

export default Editing
