import { call, put } from 'redux-saga/effects'
import axios from 'axios'
import get from 'lodash/get'
import isObject from 'lodash/isObject'

import { actionLogout } from 'store/auth/actions'

function request({ url, ...options }) {
  return axios({ url, ...options })
    .then((response) => ({ response }))
    .catch((error) => ({ error }))
}

export function* makeRequest(options) {
  const result = yield call(request, options)

  if (result.error) {
    const status = get(result, 'error.response.status')
    const data = get(result, 'error.response.data')

    if (status === 401 || status === 403) {
      yield put(actionLogout())
    }

    return {
      error: isObject(data) ? data : 'Произошла неизвестная ошибка',
    }
  }

  return {
    headers: get(result, 'response.headers'),
    data: get(result, 'response.data'),
  }
}
