import React, { useState, useCallback, useMemo } from 'react'

import { isNumeric } from 'utils/validation'
import { getRowFields } from 'utils/fields'
import { DEFAULT_COUNT_ROW, RADIO_ITEMS_MIXES } from 'utils/constants'

import IconMixes from 'icons/mixes'
import Input from 'components/Input'
import RadioButton from 'components/RadioButton'

import styles from './styles.module.scss'

const Mixes = () => {
  const [rows, setRows] = useState(Array.from({ length: DEFAULT_COUNT_ROW }, () => getRowFields()))

  const handleChangeInput = useCallback(
    (index) => (e) => {
      const { name, value } = e.target
      rows[index][name] = value

      if (name === 'count' && value && !isNumeric(value)) {
        return
      }

      setRows([...rows])
    },
    [rows]
  )

  const handleChangeValue = useCallback((index) => (e) => {
    const { name, value } = e.target
    const parseName = name.split('_')[0]
    rows[index][parseName] = value

    setRows(rows)
  })

  const getTotal = useMemo(() => {
    // eslint-disable-next-line no-param-reassign
    return rows.reduce((acc, row) => (acc += row.count ? parseInt(row.count) : 0), 0)
  }, [rows])

  const renderRow = (row, index) => (
    <tr key={index}>
      <td>{index + 1}</td>
      <td>
        <Input
          id={`oil_title_${index}`}
          name="oil_title"
          handleChange={handleChangeInput(index)}
          value={row.oil_title}
          containerClassName={styles.containerInput}
        />
      </td>
      <td>
        <RadioButton
          id={`radio_${index}`}
          name={`volatility_${index}`}
          items={RADIO_ITEMS_MIXES}
          onChange={handleChangeValue(index)}
          containerClassName={styles.containerRadio}
        />
      </td>
      <td>
        <Input
          id={`count_${index}`}
          name="count"
          handleChange={handleChangeInput(index)}
          value={row.count}
          containerClassName={styles.containerInput}
        />
      </td>
    </tr>
  )

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <IconMixes size={30} />
        Смеси
      </div>
      <table>
        <thead>
          <tr>
            <td>№</td>
            <td>Эфирное масло</td>
            <td>Летучесть</td>
            <td>Кол-во капель</td>
          </tr>
        </thead>
        <tbody>{rows.map((row, index) => renderRow(row, index))}</tbody>
        <tfoot>
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>{getTotal || ''}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  )
}

export default Mixes
