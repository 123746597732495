import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'

import styles from './styles.module.scss'

const RadioButton = ({
  items,
  label,
  name,
  selectedItem,
  onChange,
  containerClassName,
  radioClassName,
}) => {
  return (
    <div className={classNames(styles.container, containerClassName)}>
      {label && <span className={styles.label}>{label}</span>}
      <div className={styles.group}>
        {items.map((item, index) => (
          <label key={index} className={classNames(styles.radio, radioClassName)}>
            <input
              type="radio"
              name={name}
              value={item.value}
              checked={selectedItem ? item.value === selectedItem : ''}
              onChange={onChange}
            />
            <span></span>
            {item.name}
          </label>
        ))}
      </div>
    </div>
  )
}

RadioButton.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})),
  label: PropTypes.string,
  name: PropTypes.string,
  selectedItem: PropTypes.string,
  containerClassName: PropTypes.string,
  radioClassName: PropTypes.string,
  onChange: PropTypes.func,
}

RadioButton.defaultProps = {
  items: [],
  label: '',
  name: '',
  selectedItem: '',
  containerClassName: '',
  radioClassName: '',
  onChange: noop,
}

export default RadioButton
