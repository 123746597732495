import { makeRequest } from 'utils/request'

export const loadResults = () =>
  makeRequest({
    url: '/api/results',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
  })

export const createResult = (data) =>
  makeRequest({
    url: '/api/results',
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })

export const deleteResult = (id) =>
  makeRequest({
    url: `/api/results/${id}`,
    method: 'delete',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
  })

export const sendResults = (data) =>
  makeRequest({
    url: `/api/results/send`,
    method: 'post',
    headers: {
      Accept: 'application/json;charset=UTF-8',
    },
    data,
  })
