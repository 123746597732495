import { call, takeEvery, put } from 'redux-saga/effects'
import { toastr } from 'react-redux-toastr'

import { loadResults, createResult, deleteResult, sendResults } from 'api/results'
import { actionShowLoading, actionHideLoading } from 'store/ui/actions'

import {
  actionLoadResultsRequest,
  actionLoadResultsSuccess,
  actionCreateResulsRequest,
  actionCreateResulsSuccess,
  actionDeleteResulsRequest,
  actionDeleteResulsSuccess,
  actionSendResultsRequest,
} from './actions'

function* loadResultsSaga() {
  yield put(actionShowLoading())

  const result = yield call(loadResults)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  yield put(actionLoadResultsSuccess(result.data))
  yield put(actionHideLoading())
}

function* createResultSaga({ payload }) {
  yield put(actionShowLoading())

  const result = yield call(createResult, payload)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  yield put(actionCreateResulsSuccess(result.data))
  yield put(actionHideLoading())
}

function* deleteResultSaga({ payload }) {
  yield put(actionShowLoading())

  const { id } = payload
  const result = yield call(deleteResult, id)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  toastr.success('Результат успешно удален')

  yield put(actionDeleteResulsSuccess(id))
  yield put(actionHideLoading())
}

function* sendResultsSaga({ payload }) {
  yield put(actionShowLoading())

  const result = yield call(sendResults, payload)

  if (result.error) {
    toastr.error(result.error.message || 'Произошла ошибка. Попробуйте повторить позднее')
    yield put(actionHideLoading())
    return
  }

  yield put(actionHideLoading())
}

function* watchLoadResults() {
  yield takeEvery(actionLoadResultsRequest, loadResultsSaga)
}

function* watchCreateResult() {
  yield takeEvery(actionCreateResulsRequest, createResultSaga)
}

function* watchDeleteResult() {
  yield takeEvery(actionDeleteResulsRequest, deleteResultSaga)
}

function* watchSendResults() {
  yield takeEvery(actionSendResultsRequest, sendResultsSaga)
}

export default [watchLoadResults(), watchCreateResult(), watchDeleteResult(), watchSendResults()]
