import React from 'react'
import PropTypes from 'prop-types'

const Upload = ({ size, color }) => (
  <svg
    width={size}
    height={(65 * size) / 100}
    viewBox="0 0 100 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M76.065 18.195C74.685 18.195 73.335 18.32 72.02 18.535C68.74 7.81 58.58 0 46.545 0C31.85 0 19.945 11.64 19.945 25.995C19.945 27.275 20.045 28.535 20.23 29.775C19.5184 29.6869 18.8021 29.6418 18.085 29.64C8.095 29.64 0 37.55 0 47.315C0 57.08 8.095 65 18.085 65H40V45H27.5L50 20L72.5 45H60V65H76.065C89.28 65 100 54.52 100 41.6C100 28.67 89.28 18.195 76.065 18.195V18.195Z"
      fill={color}
    />
  </svg>
)

Upload.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Upload.defaultProps = {
  size: 100,
  color: 'currentColor',
}

export default Upload
