import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { validateResultFields } from 'utils/validation'
import { getDefaultPreparationFields } from 'utils/fields'

import UserData from 'icons/userData'
import Input from 'components/Input'
import RectangleButton from 'components/RectangleButton'

import styles from './styles.module.scss'

const Preparation = ({ onSave, onCancel, data }) => {
  const [fields, setFields] = useState(data || getDefaultPreparationFields())

  const handleChangeInput = useCallback(
    (e) => {
      const { name, value } = e.target
      setFields({ ...fields, [name]: value })
    },
    [fields, setFields]
  )

  const handleSave = useCallback(() => {
    if (validateResultFields(fields)) {
      onSave(fields)
    }
  }, [onSave, fields])

  return (
    <>
      <div className={styles.title}>
        <UserData size={30} />
        Данные пользователя
      </div>
      <div className={styles.form}>
        <Input
          label="Имя"
          name="first_name"
          placeholder="Введите имя..."
          handleChange={handleChangeInput}
          value={fields.first_name}
        />
        <Input
          label="Фамилия"
          name="last_name"
          placeholder="Введите фамилию..."
          handleChange={handleChangeInput}
          value={fields.last_name}
        />
        <Input
          label="Email"
          name="email"
          placeholder="Введите Email..."
          handleChange={handleChangeInput}
          value={fields.email}
        />
      </div>
      <div className={styles.buttons}>
        <RectangleButton title="Начать тестирование" theme="blue" onClick={handleSave} />
        <RectangleButton title="Отмена" theme="red_border" onClick={onCancel} />
      </div>
    </>
  )
}

Preparation.propTypes = {
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  data: PropTypes.shape({}),
}

Preparation.defaultProps = {
  onSave: noop,
  onCancel: noop,
  data: null,
}

export default Preparation
