import { createSelector } from 'reselect'
import { selectOilsEntities } from 'store/oils/selectors'

export const selectResults = (state) => state.results

export const selectResultsEntities = createSelector(
  selectResults,
  (results = {}) => results.entities
)

export const selectIsCreating = createSelector(selectResults, (results = {}) => results.isCreating)
export const selectIsLoading = createSelector(selectResults, (results = {}) => results.isLoading)

export const selectResultById = createSelector(
  selectResults,
  (_, props) => props,
  (results = {}, props) => results.entities.find((i) => i._id === props.id)
)

export const selectResultByIdWithOilsGroup = createSelector(
  selectResults,
  selectOilsEntities,
  (_, props) => props,
  (results = {}, oils, props) => {
    const result = results.entities.find((i) => i._id === props.id)

    if (!result) {
      return result
    }

    const resultWithOilsGroup = result.test_result.reduce((acc, r) => {
      const oil = oils.find((o) => o.en_title === r.en_title) || {}

      return [...acc, { ...r, group: oil.group || '' }]
    }, [])

    return { ...result, test_result: resultWithOilsGroup }
  }
)
