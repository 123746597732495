import React, { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { TESTING_MODES } from 'utils/constants'
import Preparation from 'containers/Testing/Preparation'
import Test from 'containers/Testing/Test'
import Result from 'containers/Testing/Result'

import { preparationResultForSendMail } from 'containers/Testing/utils'
import { selectOilsEntities } from 'store/oils/selectors'
import { actionCreateResulsRequest } from 'store/results/actions'

import styles from './styles.module.scss'

const mapStateToProps = (state) => ({
  oils: selectOilsEntities(state),
})

const mapDispatchToProps = {
  saveResult: actionCreateResulsRequest,
}

const Testing = ({ oils, saveResult }) => {
  const [fields, setFields] = useState(null)
  const [mode, setMode] = useState(TESTING_MODES.PREPARATION)
  const [testResult, setTestResult] = useState({})

  const handleSavePreparation = useCallback((fields) => {
    setMode(TESTING_MODES.TESTING)
    setFields(fields)
  })

  const handleCancel = useCallback(() => {
    setFields(null)
    setTestResult({})
    setMode(TESTING_MODES.PREPARATION)
  })

  const handleGetResult = useCallback((test) => {
    setTestResult(test)
    setMode(TESTING_MODES.RESULT)
  }, [])

  const handleBack = useCallback(() => {
    if (mode === TESTING_MODES.TESTING) {
      setFields(fields)
      setMode(TESTING_MODES.PREPARATION)
    }
    if (mode === TESTING_MODES.RESULT) {
      setMode(TESTING_MODES.TESTING)
    }
  }, [fields, mode])

  const handleSave = useCallback(
    (testResult, withSend = false) => {
      const { first_name, last_name, email } = fields
      const result = {
        first_name,
        last_name,
        email,
        test_result: testResult,
      }
      const resultForSendMail = withSend ? preparationResultForSendMail(testResult) : null

      saveResult({ data: result, withSend, resultForSendMail })
      handleCancel()
    },
    [fields, testResult, saveResult]
  )

  return (
    <div className={styles.container}>
      {mode === TESTING_MODES.PREPARATION && (
        <Preparation onSave={handleSavePreparation} onCancel={handleCancel} data={fields} />
      )}

      {mode === TESTING_MODES.TESTING && (
        <Test
          oils={oils}
          testResult={testResult}
          onGetResult={handleGetResult}
          onCancel={handleCancel}
          onBack={handleBack}
        />
      )}

      {mode === TESTING_MODES.RESULT && (
        <Result
          oils={oils}
          testResult={testResult}
          onSave={handleSave}
          onCancel={handleCancel}
          onBack={handleBack}
        />
      )}
    </div>
  )
}

Testing.propTypes = {
  oils: PropTypes.arrayOf(PropTypes.shape({})),
  saveResult: PropTypes.func,
}

Testing.defaultProps = {
  oils: [],
  saveResult: noop,
}

export default connect(mapStateToProps, mapDispatchToProps)(Testing)
