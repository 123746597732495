import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import styles from './styles.module.scss'

const Input = ({
  label,
  type,
  name,
  min,
  placeholder,
  id,
  value,
  icon,
  handleChange,
  handleBlur,
  handleFocus,
  handleKeyPress,
  inputClassName,
  containerClassName,
  disabled,
  refElement,
}) => {
  const renderLabel = () => {
    if (label.includes('*')) {
      return (
        <label htmlFor={id}>
          {label.replace('*', '')}
          <span>*</span>
        </label>
      )
    }
    return <label htmlFor={id}>{label}</label>
  }

  return (
    <div className={classNames(containerClassName, styles.container)}>
      {label && renderLabel()}
      {icon && icon}
      <div className={styles.inputWrapper}>
        <input
          ref={refElement}
          type={type}
          name={name}
          min={min}
          id={id || name}
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onKeyPress={handleKeyPress}
          className={classNames(styles.input, inputClassName, { [styles.date]: type === 'date' })}
          disabled={disabled}
          autoComplete="off"
        />
      </div>
    </div>
  )
}

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.node,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleFocus: PropTypes.func,
  handleKeyPress: PropTypes.func,
  inputClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
  refElement: PropTypes.shape({}),
}

Input.defaultProps = {
  label: '',
  type: 'text',
  name: '',
  min: '',
  placeholder: '',
  id: '',
  value: '',
  icon: null,
  handleChange: noop,
  handleBlur: noop,
  handleFocus: noop,
  handleKeyPress: noop,
  inputClassName: '',
  containerClassName: '',
  disabled: false,
  refElement: null,
}

export default Input
