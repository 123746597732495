import { createAction } from 'redux-actions'

export const actionLoadOilsRequest = createAction('LOAD_OILS_REQUEST')
export const actionLoadOilsSuccess = createAction('LOAD_OILS_SUCCESS')

export const actionCreateOilRequest = createAction('CREATE_OIL_REQUEST')
export const actionCreateOilSuccess = createAction('CREATE_OIL_SUCCESS')

export const actionUpdateOilRequest = createAction('UPDATE_OIL_REQUEST')
export const actionUpdateOilSuccess = createAction('UPDATE_OIL_SUCCESS')

export const actionDeleteOilRequest = createAction('DELETE_OIL_REQUEST')
export const actionDeleteOilSuccess = createAction('DELETE_OIL_SUCCESS')
