import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ClickOutside from 'react-outside-click-handler'
import classNames from 'classnames'
import noop from 'lodash/noop'

import IconArrowDown from 'icons/arrowDown'

import styles from './styles.module.scss'

const Sorting = ({ selectedItem, items, onSelect }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleExpanded = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const handleSelect = useCallback(
    (value) => () => {
      onSelect(value)
      setIsExpanded(false)
    },
    [onSelect]
  )

  return (
    <ClickOutside onOutsideClick={isExpanded ? toggleExpanded : noop}>
      <div className={styles.sortingContainer}>
        <div
          className={classNames(styles.title, { [styles.expanded]: isExpanded })}
          onClick={toggleExpanded}
        >
          {selectedItem.title}
          <IconArrowDown size={12} />
        </div>
        {isExpanded && (
          <ul className={styles.list}>
            {items.map((item) => (
              <li
                key={item.id}
                className={classNames({ [styles.selected]: item.id === selectedItem.id })}
                onClick={handleSelect(item.value)}
              >
                {item.title}
              </li>
            ))}
          </ul>
        )}
      </div>
    </ClickOutside>
  )
}

Sorting.propTypes = {
  selectedItem: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.number,
  }),
  items: PropTypes.arrayOf(PropTypes.shape({ title: PropTypes.string })),
  onSelect: PropTypes.func,
}

Sorting.defaultProps = {
  selectedItem: {},
  items: [],
  onSelect: noop,
}

export default Sorting
