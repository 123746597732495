import React, { useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import TRL from 'cyrillic-to-translit-js'
import noop from 'lodash/noop'

import { validateResultFields } from 'utils/validation'
import { getDefaultResultFields, getDefaultTestResultFields } from 'utils/fields'

import Input from 'components/Input'
import TextArea from 'components/Textarea'
import RectangleButton from 'components/RectangleButton'
import IconTrash from 'icons/trash'
import IconAddingresults from 'icons/addingResults'
import { preparationResult } from 'containers/Testing/utils'

import { actionCreateResulsRequest } from 'store/results/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  saveResult: actionCreateResulsRequest,
}

const AddingResults = ({ saveResult }) => {
  const [fields, setFields] = useState(getDefaultResultFields())

  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setFields({ ...fields, [name]: value })
  }

  const handleChangeResultInput = useCallback(
    (index) => (e) => {
      const { name, value } = e.target

      fields.test_result[index][name] = value

      if (name === 'title') {
        fields.test_result[index].en_title = TRL().transform(value)
      }

      setFields({ ...fields })
    },
    [fields]
  )

  const handleAddResultBlock = useCallback(() => {
    fields.test_result.push(getDefaultTestResultFields())
    setFields({ ...fields })
  }, [fields])

  const handleDeleteResultBlock = useCallback(
    (index) => () => {
      fields.test_result.splice(index, 1)
      setFields({ ...fields })
    },
    [fields]
  )

  const handleCancel = useCallback(() => {
    setFields(getDefaultResultFields())
  })

  const handleSave = useCallback(() => {
    if (validateResultFields(fields)) {
      const preparedResult = preparationResult(fields)
      saveResult({ data: preparedResult })
      handleCancel()
    }
  }, [fields])

  const toggleCollapse = useCallback(
    (index) => () => {
      fields.test_result[index].collapse = !fields.test_result[index].collapse
      setFields({ ...fields })
    },
    [fields]
  )

  const renderResultBlock = (result, index) => (
    <div key={index} className={styles.resultWrapper}>
      <div className={classNames(styles.header, { [styles.isCollapsed]: result.collapse })}>
        <div className={styles.result_title}>{result.title}</div>
        <div className={styles.actions}>
          <div className={styles.collapse} onClick={toggleCollapse(index)}>
            {result.collapse ? 'Развернуть' : 'Свернуть'}
          </div>
          {fields.test_result.length > 1 && (
            <div className={styles.delete} onClick={handleDeleteResultBlock(index)}>
              <IconTrash size={16} />
            </div>
          )}
        </div>
      </div>
      <div className={classNames(styles.result, { [styles.isCollapsed]: result.collapse })}>
        <Input
          name="title"
          label="Название масла"
          handleChange={handleChangeResultInput(index)}
          value={result.title}
          containerClassName={styles.inputContainer}
        />
        <div className={styles.fieldGroup}>
          <TextArea
            name="plus"
            label="Плюс"
            onChange={handleChangeResultInput(index)}
            value={result.plus}
          />
          <TextArea
            name="minus"
            label="Минус"
            onChange={handleChangeResultInput(index)}
            value={result.minus}
          />
        </div>
        <div className={styles.fieldGroup}>
          <TextArea
            name="general"
            label="Общее"
            onChange={handleChangeResultInput(index)}
            value={result.general}
          />
          <TextArea
            name="question"
            label="Вопрос"
            onChange={handleChangeResultInput(index)}
            value={result.question}
          />
        </div>
      </div>
    </div>
  )

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <IconAddingresults size={30} />
        Добавление результатов
      </div>
      <div className={styles.buttons}>
        <RectangleButton title="Добавить" onClick={handleSave} />
        <RectangleButton title="Отмена" theme="red_border" onClick={handleCancel} />
      </div>
      <div className={styles.fieldGroup}>
        <Input
          name="first_name"
          label="Имя"
          placeholder="Введите имя..."
          handleChange={handleChangeInput}
          value={fields.first_name}
          containerClassName={styles.inputContainer}
        />
        <Input
          name="last_name"
          label="Фамилия"
          placeholder="Введите фамилию..."
          handleChange={handleChangeInput}
          value={fields.last_name}
          containerClassName={styles.inputContainer}
        />
      </div>
      <div className={styles.fieldGroup}>
        <Input
          name="email"
          label="Email"
          placeholder="Введите Email..."
          handleChange={handleChangeInput}
          value={fields.email}
          containerClassName={styles.inputContainer}
        />
        <Input
          name="create_at"
          label="Дата"
          type="date"
          min="1900-01-01"
          handleChange={handleChangeInput}
          value={fields.create_at}
          containerClassName={styles.inputContainer}
        />
      </div>
      <div className={styles.results}>
        {fields.test_result.map((result, index) => renderResultBlock(result, index))}
        <RectangleButton
          title="+ Добавить результат"
          theme="blue_border"
          onClick={handleAddResultBlock}
        />
      </div>
    </div>
  )
}

AddingResults.propTypes = {
  saveResult: PropTypes.func,
}

AddingResults.defaultProps = {
  saveResult: noop,
}

export default connect(null, mapDispatchToProps)(AddingResults)
