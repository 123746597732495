import React, { useCallback, useState } from 'react'
import TRL from 'cyrillic-to-translit-js'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import { validateOilFields } from 'utils/validation'
import { getDefaultOilFields } from 'utils/fields'
import {
  GROUP_OILS_ITEMS,
  VOLATILITY_OILS_ITEMS,
  GROUP_OILS_ITEMS_MAP,
  VOLATILITY_OILS_ITEMS_MAP,
} from 'utils/constants'

import IconUpload from 'icons/upload'
import IconAddingOils from 'icons/addingOils'
import DragAndDRopArea from 'containers/DragAndDropArea'
import Input from 'components/Input'
import TextArea from 'components/Textarea'
import RectangleButton from 'components/RectangleButton'
import Select from 'components/Select'

import { actionCreateOilRequest } from 'store/oils/actions'

import styles from './styles.module.scss'

const mapDispatchToProps = {
  addOil: actionCreateOilRequest,
}

const AddingOils = ({ addOil }) => {
  const [fileURL, setFileURL] = useState(null)
  const [fields, setFields] = useState(getDefaultOilFields())
  const [file, setFile] = useState(null)

  const handleDrop = useCallback((files) => {
    if (files && files.length) {
      const file = files[0]
      const fileURL = URL.createObjectURL(file)
      setFileURL(fileURL)
      setFile(file)
    }
  })

  const uploadFile = useCallback((e) => {
    handleDrop(e.target.files)
  })

  const handleChangeInput = useCallback(
    (e) => {
      const { name, value } = e.target
      setFields({ ...fields, [name]: value })
    },
    [fields]
  )

  const handleSelect = useCallback(
    (value, name) => {
      setFields({ ...fields, [name]: value })
    },
    [fields]
  )

  const handleSetTranslit = useCallback(() => {
    setFields({ ...fields, en_title: TRL().transform(fields.title) })
  }, [fields])

  const handleCancel = useCallback(() => {
    setFields(getDefaultOilFields())
    setFileURL(null)
    setFile(null)
  })

  const handleSave = useCallback(() => {
    if (validateOilFields(fields)) {
      addOil({ fields, file })
    }
  }, [fields, file])

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <IconAddingOils size={28} additionalColor="#F4F4F4" />
        Добавление масел
      </div>
      {!fileURL && (
        <DragAndDRopArea onDrop={handleDrop}>
          <div className={styles.dropzone}>
            <IconUpload size={90} />
            <p>Перетащите файлы в эту область или нажмите для открытия файлов на компьютере</p>
            <RectangleButton
              type="file"
              title="Загрузить файл"
              externalStyle={styles.uploadButton}
              onClick={uploadFile}
            />
          </div>
        </DragAndDRopArea>
      )}

      {fileURL && (
        <>
          <div className={styles.body}>
            <div className={styles.preview}>{fileURL && <img src={fileURL} alt="oil" />}</div>
            <div className={styles.fields}>
              <Input
                name="title"
                label="Название*"
                handleChange={handleChangeInput}
                value={fields.title}
                handleBlur={handleSetTranslit}
              />
              <div className={styles.groupFieldsContainer}>
                <Select
                  name="group"
                  label="Группа"
                  items={GROUP_OILS_ITEMS}
                  placeholder="Выберите группу"
                  onSelect={handleSelect}
                  selectedItem={GROUP_OILS_ITEMS_MAP[fields.group]}
                />
                <Select
                  name="volatility"
                  label="Летучесть"
                  items={VOLATILITY_OILS_ITEMS}
                  placeholder="Выберите летучесть"
                  onSelect={handleSelect}
                  selectedItem={VOLATILITY_OILS_ITEMS_MAP[fields.volatility]}
                />
              </div>
              <TextArea
                name="plus"
                label="Плюс*"
                onChange={handleChangeInput}
                value={fields.plus}
              />
              <TextArea
                name="minus"
                label="Минус*"
                onChange={handleChangeInput}
                value={fields.minus}
              />
              <TextArea
                name="general"
                label="Общее*"
                onChange={handleChangeInput}
                value={fields.general}
              />
              <TextArea
                name="question"
                label="Вопрос*"
                onChange={handleChangeInput}
                value={fields.question}
              />
            </div>
          </div>
          <div className={styles.buttons}>
            <RectangleButton title="Добавить" onClick={handleSave} />
            <RectangleButton title="Отмена" theme="red_border" onClick={handleCancel} />
          </div>
        </>
      )}
    </div>
  )
}

AddingOils.propTypes = {
  addOil: PropTypes.func,
}

AddingOils.defaultProps = {
  addOil: noop,
}

export default connect(null, mapDispatchToProps)(AddingOils)
