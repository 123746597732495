import { createAction } from 'redux-actions'

export const actionLoadResultsRequest = createAction('LOAD_RESULTS_REQUEST')
export const actionLoadResultsSuccess = createAction('LOAD_RESULTS_SUCCESS')

export const actionCreateResulsRequest = createAction('CREATE_RESULTS_REQUEST')
export const actionCreateResulsSuccess = createAction('CREATE_RESULTS_SUCCESS')

export const actionDeleteResulsRequest = createAction('DELETE_RESULTS_REQUEST')
export const actionDeleteResulsSuccess = createAction('DELETE_RESULTS_SUCCESS')

export const actionSendResultsRequest = createAction('SEND_RESULTS_REQUEST')
