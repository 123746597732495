import { combineReducers } from 'redux'
import { reducer as toastr } from 'react-redux-toastr'

import auth from 'store/auth/reducer'
import ui from 'store/ui/reducer'
import oils from 'store/oils/reducer'
import results from 'store/results/reducer'

export default combineReducers({
  toastr,
  auth,
  ui,
  oils,
  results,
})
