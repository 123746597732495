import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'

import styles from './styles.module.scss'

const Textarea = ({
  id,
  label,
  name,
  row,
  cols,
  placeholder,
  value,
  onChange,
  onBlur,
  onFocus,
  textareaClassName,
  containerClassName,
  disabled,
}) => {
  const renderLabel = () => {
    if (label.includes('*')) {
      return (
        <label htmlFor={id}>
          {label.replace('*', '')}
          <span>*</span>
        </label>
      )
    }
    return <label htmlFor={id}>{label}</label>
  }

  return (
    <div className={classNames(styles.container, containerClassName)}>
      {label && renderLabel()}
      <textarea
        id={id}
        className={classNames(styles.textarea, textareaClassName)}
        name={name}
        rows={row}
        cols={cols}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        disabled={disabled}
      ></textarea>
    </div>
  )
}

Textarea.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  row: PropTypes.number,
  cols: PropTypes.number,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.node,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  textareaClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  disabled: PropTypes.bool,
}

Textarea.defaultProps = {
  label: '',
  name: '',
  row: null,
  cols: null,
  placeholder: '',
  id: '',
  value: '',
  onChange: noop,
  onBlur: noop,
  onFocus: noop,
  textareaClassName: '',
  containerClassName: '',
  disabled: false,
}

export default Textarea
