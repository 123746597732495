import { handleActions } from 'redux-actions'
import { actionShowLoading, actionHideLoading, actionToggleSidebar } from './actions'

const initialState = {
  isLoading: false,
  isSidebarOpen: window.innerWidth > 990,
}

export default handleActions(
  {
    [actionShowLoading]: (state) => ({
      ...state,
      isLoading: true,
    }),

    [actionHideLoading]: (state) => ({
      ...state,
      isLoading: false,
    }),

    [actionToggleSidebar]: (state, { payload }) => ({
      ...state,
      isSidebarOpen: payload,
    }),
  },
  initialState
)
