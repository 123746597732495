import React from 'react'
import PropTypes from 'prop-types'

const Test = ({ size, color }) => (
  <svg
    width={size}
    height={(37 * size) / 32}
    viewBox="0 0 32 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32 26.4127L22.3492 36.0635L16.7619 29.9683L19.1187 27.6114L22.3492 30.8419L29.6432 23.5479L32 26.4127ZM4.06349 21.8413C4.06349 18.2451 8.12698 11.6013 12.1905 6.37968C15.2381 10.3213 18.2857 15.0756 19.647 18.7327C20.866 18.2044 22.207 17.8997 23.6089 17.7778C20.9473 9.85397 12.1905 0 12.1905 0C12.1905 0 0 13.7143 0 21.8413C0 28.5663 5.4654 34.0317 12.1905 34.0317H12.8813C12.4343 32.7314 12.1905 31.3905 12.1905 29.9683C7.70032 29.9683 4.06349 26.3314 4.06349 21.8413Z"
      fill={color}
    />
  </svg>
)

Test.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Test.defaultProps = {
  size: 32,
  color: 'currentColor',
}

export default Test
