export const TABS_VALUE = {
  ADDING_OILS: 'adding_oils',
  ADDING_RESULTS: 'adding_results',
  OILS_LIST: 'oils_list',
  MIXES: 'mixes',
}

export const TABS = [
  { id: 1, name: 'Добавление масел', value: TABS_VALUE.ADDING_OILS },
  { id: 2, name: 'Добавление результатов', value: TABS_VALUE.ADDING_RESULTS },
  { id: 3, name: 'Список масел', value: TABS_VALUE.OILS_LIST },
  { id: 4, name: 'Смеси', value: TABS_VALUE.MIXES },
]

export const TESTING_MODES = {
  PREPARATION: 'preparation',
  TESTING: 'testing',
  RESULT: 'result',
}

export const DEFAULT_COUNT_ROW = 7

export const RADIO_ITEMS_MIXES = [
  { value: 'high', name: 'В' },
  { value: 'medium', name: 'С' },
  { value: 'low', name: 'Н' },
]

export const RADIO_ITEMS_TEST = [
  { value: 'double_minus', name: '--' },
  { value: 'minus', name: '-' },
  { value: 'plus', name: '+' },
  { value: 'double_plus', name: '++' },
]

export const SORTING_RESULT_ITEMS = [
  { id: 0, value: 'first_name', title: 'Сортировать по имени' },
  { id: 2, value: 'last_name', title: 'Сортировать по фамилии' },
  { id: 3, value: 'create_at', title: 'Сортировать по дате' },
]

export const SORTING_OIL_ITEMS = [
  { id: 0, value: 'title', title: 'Сортировать по названию' },
  { id: 1, value: 'create_at', title: 'Сортировать по дате' },
  { id: 2, value: 'group', title: 'Сортировать по группе' },
]

export const GROUP_OILS_ITEMS = [
  { id: 0, value: 'citrus', title: 'Цитрусы' },
  { id: 1, value: 'scented_tropical_herbs', title: 'Душистые трапические травы' },
  { id: 2, value: 'floral', title: 'Цветочные' },
  { id: 3, value: 'wood', title: 'Древесные' },
  { id: 4, value: 'scented_herbs', title: 'Душистые травы' },
  { id: 5, value: 'spices', title: 'Специи' },
  { id: 6, value: 'balsamic', title: 'Бальзамические' },
]

export const GROUP_OILS_ITEMS_MAP = GROUP_OILS_ITEMS.reduce((acc, i) => {
  return {
    ...acc,
    [i.value]: i,
  }
}, {})

export const VOLATILITY_OILS_ITEMS = [
  { id: 0, value: 'low', title: 'Низкая' },
  { id: 1, value: 'medium', title: 'Средняя' },
  { id: 2, value: 'high', title: 'Высокая' },
]

export const VOLATILITY_OILS_ITEMS_MAP = VOLATILITY_OILS_ITEMS.reduce((acc, i) => {
  return {
    ...acc,
    [i.value]: i,
  }
}, {})

export const HIDDEN_TEST_ANSWERS = ['minus', 'plus']
