import { handleActions } from 'redux-actions'
import produce from 'immer'
import uniqBy from 'lodash/uniqBy'

import {
  actionLoadOilsSuccess,
  actionCreateOilSuccess,
  actionDeleteOilSuccess,
  actionUpdateOilSuccess,
} from './actions'

const initialState = {
  entities: [],
  isLoading: false,
}

export default handleActions(
  {
    [actionLoadOilsSuccess]: (state, { payload }) => ({
      ...state,
      entities: payload,
    }),

    [actionCreateOilSuccess]: (state, { payload }) => ({
      ...state,
      entities: [...state.entities, payload],
    }),

    [actionDeleteOilSuccess]: (state, { payload }) => ({
      ...state,
      entities: state.entities.filter((i) => i._id !== payload),
    }),

    [actionUpdateOilSuccess]: (state, { payload }) =>
      produce(state, (newState) => {
        newState.entities = uniqBy([payload, ...newState.entities], '_id')
      }),
  },
  initialState
)
