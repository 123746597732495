import { toastr } from 'react-redux-toastr'

export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const validateDate = (date) => {
  const re = /[0-9]{4}-[0-9]{2}-[0-9]{2}/
  return re.test(date)
}

export const isNumeric = (value) => {
  return /^-?\d+$/.test(value)
}

export const validateResultFields = (fields) => {
  const { first_name: firstName, last_name: lastName, email, create_at: createAt } = fields

  if (!firstName || !lastName || !email) {
    toastr.error('Заполните все поля')
    return false
  }

  if (createAt !== undefined && !validateDate(createAt)) {
    toastr.error('Неверный формат даты')
    return false
  }

  if (!validateEmail(email)) {
    toastr.error('Неверный формат Email')
    return false
  }

  return true
}

export const validateOilFields = (fields) => {
  const { title, plus, minus, general, question } = fields

  if (!title || !plus || !minus || !general || !question) {
    toastr.error('Заполните все обязательные поля')
    return false
  }

  return true
}
