import React from 'react'
import PropTypes from 'prop-types'

const Menu = ({ size, color }) => (
  <svg
    width={size}
    height={(14 * size) / 20}
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 0V2.22222H0V0H20ZM0 13.3333H20V11.1111H0V13.3333ZM0 7.77778H20V5.55556H0V7.77778Z"
      fill={color}
    />
  </svg>
)

Menu.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
}

Menu.defaultProps = {
  size: 20,
  color: 'currentColor',
}

export default Menu
