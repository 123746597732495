export const getDefaultOilFields = () => ({
  title: '',
  group: '',
  volatility: '',
  en_title: '',
  plus: '',
  minus: '',
  general: '',
  question: '',
})

export const getDefaultTestResultFields = () => ({
  title: '',
  en_title: '',
  general: '',
  minus: '',
  plus: '',
  question: '',
  collapse: false,
})

export const getDefaultResultFields = () => ({
  first_name: '',
  last_name: '',
  email: '',
  create_at: '',
  test_result: [getDefaultTestResultFields()],
})

export const getDefaultLoginFields = () => ({
  email: '',
  password: '',
})

export const getRowFields = () => ({
  oil_title: '',
  volatility: '',
  count: '',
})

export const getDefaultPreparationFields = () => ({
  first_name: '',
  last_name: '',
  email: '',
})
