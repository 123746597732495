import React from 'react'

import image from 'images/aroma.png'

import styles from './styles.module.scss'

const Main = () => {
  return (
    <div className={styles.container}>
      <img src={image} alt="aroma" />
      <div className={styles.title}>Страница находится в разработке...</div>
    </div>
  )
}

export default Main
