import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import noop from 'lodash/noop'

import { RADIO_ITEMS_TEST, HIDDEN_TEST_ANSWERS } from 'utils/constants'
import RectangleButton from 'components/RectangleButton'
import { getStringResultDescription } from 'containers/Testing/utils'
import IconResults from 'icons/results'

import styles from './styles.module.scss'

const Result = ({ oils, testResult, onSave, onCancel, onBack }) => {
  const [expandedBlockIds, setExpandedBlockId] = useState([])

  const generateResultForSave = () =>
    oils.reduce((acc, oil) => {
      const { title, en_title } = oil
      const result = testResult[oil._id]

      if (result) {
        acc.push({
          title,
          en_title,
          description: getStringResultDescription(result, oil),
          answer: result,
        })
      }

      return acc
    }, [])

  const toggleExpanded = useCallback(
    (id) => () => {
      const newExpandedBlockIds = expandedBlockIds.includes(id)
        ? expandedBlockIds.filter((i) => i !== id)
        : [...expandedBlockIds, id]
      setExpandedBlockId(newExpandedBlockIds)
    },
    [expandedBlockIds]
  )

  const renderContentMinus = (result, oil) => {
    if (result === 'minus') {
      return (
        <>
          <p>
            <span>Минус:</span>
            {oil[result]}
          </p>
          <br />
          <p>
            <span>Общее:</span>
            {oil.general}
          </p>
        </>
      )
    }

    if (result === 'double_minus') {
      return (
        <>
          <p>
            <span>Вопрос:</span>
            {oil.question}
          </p>
          <br />
          <p>
            <span>Минус:</span>
            {oil.minus}
          </p>
          <br />
          <p>
            <span>Общее:</span>
            {oil.general}
          </p>
        </>
      )
    }

    return null
  }

  const renderContentPlus = (result, oil) => {
    if (result === 'plus') {
      return (
        <>
          <p>
            <span>Плюс:</span>
            {oil[result]}
          </p>
          <br />
          <p>
            <span>Общее:</span>
            {oil.general}
          </p>
        </>
      )
    }

    if (result === 'double_plus') {
      return (
        <>
          <p>
            <span>Плюс:</span>
            {oil.plus}
          </p>
          <br />
          <p>
            <span>Общее:</span>
            {oil.general}
          </p>
        </>
      )
    }

    return null
  }

  const handleSave = useCallback(
    (withSend) => () => {
      onSave(generateResultForSave(), withSend)
    },
    [onSave, generateResultForSave]
  )

  const parseSumbolResult = (symbol) => {
    const symbolLength = symbol.name.length

    if (symbolLength > 1) {
      const splitSymbol = symbol.name.split('')

      return (
        <span>
          <span>{splitSymbol[0]}</span>
          <span>{splitSymbol[1]}</span>
        </span>
      )
    }

    return <span>{symbol.name}</span>
  }

  return (
    <div className={styles.results}>
      <div className={styles.title}>
        <IconResults size={30} />
        Результаты
        <RectangleButton title="Назад" onClick={onBack} />
      </div>
      {oils.map((oil) => {
        const result = testResult[oil._id] || ''
        const resultSymbol = RADIO_ITEMS_TEST.find((i) => i.value === result) || {}

        if (!result) {
          return null
        }

        return (
          <div key={oil._id} className={styles.result}>
            <div
              className={styles.title}
              onClick={HIDDEN_TEST_ANSWERS.includes(result) ? toggleExpanded(oil._id) : noop}
            >
              {oil.title}
              {resultSymbol.name && <p>Результат: {parseSumbolResult(resultSymbol)}</p>}
            </div>
            <div
              className={classNames({
                [styles.content]: !HIDDEN_TEST_ANSWERS.includes(result),
                [styles.contentExpanded]: HIDDEN_TEST_ANSWERS.includes(result),
                [styles.expanded]: expandedBlockIds.includes(oil._id),
              })}
            >
              {renderContentMinus(result, oil)}
              {renderContentPlus(result, oil)}
            </div>
          </div>
        )
      })}
      <div className={styles.buttons}>
        <RectangleButton title="Сохранить" onClick={handleSave()} />
        <RectangleButton
          title="Сохранить и отправить"
          theme="blue_border"
          onClick={handleSave(true)}
        />
        <RectangleButton title="Отмена" theme="red_border" onClick={onCancel} />
      </div>
    </div>
  )
}

Result.propTypes = {
  oils: PropTypes.arrayOf(PropTypes.shape({})),
  testResult: PropTypes.shape({}),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
}

Result.defaultProps = {
  oils: [],
  testResult: {},
  onSave: noop,
  onCancel: noop,
  onBack: noop,
}

export default Result
